<template>
  <div :id="slice.primary.reference" class="card clamped--text">
    <div class="wrapper">
      <div class="profile-details">
        <div class="position">
          {{ slice.primary.position }}
        </div>
        <div class="name">
          {{ slice.primary.name }}
        </div>
        <div class="bio">
          <PrismicRichText :field="slice.primary.bio" />
        </div>
      </div>
      <div class="profile-pic-wrapper">
        <PrismicImage :field="slice.primary.profilePicture" class="profile-pic" />
      </div>
    </div>
    <div v-if="slice.items && slice.items[0] && slice.items[0].badge && slice.items[0].badge.url" class="badges-wrapper">
      <span class="badges-title">Magento Certifications</span>
      <div class="badges">
        <div v-for="(item, i) in slice.items" :key="`slice-item-${i}`" class="badge">
          <PrismicImage :field="item.badge" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
export default {
  name: 'ProfileCard',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
};
</script>

<style scoped lang="scss">
.card {
  border: $border-width solid $black;
  border-radius: $border-radius-profile;
  margin-block: var(--spacer-base);
  padding: 0;
  overflow: hidden;
}
.wrapper {
  padding: 0;
  display: flex;
  gap: min(1vmin, 1.5em);
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.profile-details {
  padding: var(--spacer-lg) 3em;
  flex: 1.5 0;
}

.position {
  font-size: $font-size-up-1;
  padding: var(--spacer-xs) 0;
}
.name {
  font-family: $font-secondary;
  font-size: $font-size-up-2;
  font-weight: $font-weight-bold;
  padding-bottom: var(--spacer-base);
}
.bio {
  line-height: 1.5;
}

.bio ::v-deep p:first-child {
  margin-block-start: 0;
}

.bio ::v-deep p:last-child {
  margin-block-end: 0;
}

.profile-pic {
  max-width: none;
  object-fit: cover;
  object-position: center;
  max-height: none;
  min-width: 100%;
  min-height: 100%;

  @media (max-width: 768px) {
    max-width: 250px;
    min-width: 0;
    min-height: 0;

    border-radius: 40px;
  }
}

.profile-pic-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    align-items: center;
  }
}

.profile-pic-wrapper::after {
  content: ' ';
  background-color: #fff;
  position: absolute;
  width: 30px;
  height: 100%;
  top: 0;
  left: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.badges-wrapper {
  background: $pink;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacer-base);
  padding: var(--spacer-xs) 3em;
  row-gap: var(--spacer-sm);
}
.badges {
  display: flex;
  gap: var(--spacer-xs);
}
.badges-title {
  font-size: $font-size-body;
  line-height: 1.3;
  text-transform: uppercase;
  width: 130px;
  margin-top: 1.5em;
  font-weight: var(--font-weight--bold);
}
@media (max-width: 768px) {
  .profile-pic-wrapper::after {
    display: none;
  }
  .wrapper {
    padding: var(--spacer-sm);
  }
  .badges-wrapper {
    padding: var(--spacer-xs) var(--spacer-sm);
  }
  .profile-pic {
    margin: 0;
    width: auto;
  }
  .position {
    padding: var(--spacer-sm) 0;
  }
  .badges-title {
    width: 100%;
    margin-top: var(--spacer-xs);
  }
}
</style>
