<template>
  <div v-show="glide" class="sf-carousel">
    <div ref="controlsPrev" class="sf-carousel__controls sf-carousel__controls--prev">
      <slot name="prev" v-bind="{ go: () => go('prev') }">
        <SfArrow
          aria-label="previous"
          data-testid="carousel-prev-button"
          @click="go('prev')"
        />
      </slot>
    </div>
    <div ref="controlsNext" class="sf-carousel__controls sf-carousel__controls--next">
      <slot name="next" v-bind="{ go: () => go('next') }">
        <SfArrow
          aria-label="next"
          class="sf-arrow--right"
          data-testid="carousel-next-button"
          @click="go('next')"
        />
      </slot>
    </div>
    <div class="sf-carousel__wrapper">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides sf-carousel__slides">
            <slot />
          </ul>
          <div v-if="items > 1" class="glide__bullets" data-glide-el="controls[nav]">
            <button
              v-for="(item, i) in items"
              :key="i"
              :data-glide-dir="`=${i}`"
              :aria-label="`Go to slide ${i}`"
              :class="['glide__bullet', { 'glide__bullet--active': i === currentPage }, bulletsVariants]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable  */
import Vue from "vue";
import { ref } from '@nuxtjs/composition-api';
import SfCarouselItem from "@storefront-ui/vue/src/components/organisms/SfCarousel/_internal/SfCarouselItem.vue"
import SfArrow from "@storefront-ui/vue/src/components/atoms/SfArrow/SfArrow.vue";
import Glide from "@glidejs/glide";
import { Logger } from '~/helpers/logger'
Vue.component("SfCarouselItem", SfCarouselItem);
export default {
  name: "CustomCarousel",
  components: {
    SfArrow,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => ([])
    },
    variant: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      glide: ref(null),
      defaultSettings: {
        type: "carousel",
        rewind: true,
        perView: 3,
        slidePerPage: false,
        gap: 0,
        breakpoints: {
          1023: {
            perView: 2,
            peek: {
              before: 0,
              after: 50,
            },
          },
        },
      }
    };
  },
  computed: {
    bulletsVariants() {
      const variants = {
        'default': '',
        'text-cards': 'variant--text-cards'
      }
      return variants[this.variant ?? 'default']
    },
    currentPage() {
      return this?.glide?.index ?? 1
    },
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints }
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints }
      }
      const breakpointsPerView = [
        {key: 'perViewTablet', value: 1023},
        {key: 'perViewMobile', value: 767}
      ];

      breakpointsPerView.forEach((item) => {
        if (this.settings?.[item.key]) {
          breakpoints[item.value].perView = this.settings?.[item.key]
        }
      });

      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints,
      };
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      try {
        if (!this.$slots.default) return;
        const glide = new Glide(this.$refs.glide, this.mergedOptions);
        const size = this.$slots.default.filter((slot) => slot.tag).length;
        if (size <= glide.settings.perView) {
          glide.settings.perView = size;
          glide.settings.rewind = false;
          this.$refs.controlsNext.style.display = "none";
          this.$refs.controlsPrev.style.display = "none";
        }
        glide.mount();
        glide.on("run.before", (move) => {
          const { slidePerPage, rewind, type } = this.mergedOptions;
          if (!slidePerPage) return;
          const { perView } = glide.settings;
          if (!perView > 1) return;
          const { direction } = move;
          let page, newIndex;
          switch (direction) {
            case ">":
            case "<":
              page = Math.ceil(glide.index / perView);
              newIndex =
                page * perView + (direction === ">" ? perView : -perView);
              if (newIndex >= size) {
                if (type === "slider" && !rewind) {
                  newIndex = glide.index;
                } else {
                  newIndex = 0;
                }
              } else if (newIndex < 0 || newIndex + perView > size) {
                if (type === "slider" && !rewind) {
                  newIndex = glide.index;
                } else {
                  newIndex = size - perView;
                }
              }
              move.direction = "=";
              move.steps = newIndex;
          }
        });
        this.glide = glide;
      } catch (e) {
        Logger.error('Failed to initialize carousel.', e)
      }
    });
  },
  methods: {
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case "prev":
          this.glide.go("<");
          break;
        case "next":
          this.glide.go(">");
          break;
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfCarousel.scss";
.sf-carousel__wrapper {
  max-width: none;
}
.sf-carousel__controls {
  width: auto;
  z-index: 10;
}
.sf-carousel__controls:hover {
  filter: brightness(85%);
}
.sf-carousel__controls--next {
  left: auto;
  right: 0;
}
.variant--text-cards {
  --carousel-bullet-background--active: #{$yellow};
  --carousel-arrow-background: #{$yellow-light-20}
}
.glide__bullets {
  text-align: center;
}
.glide__bullet {
  box-sizing: content-box;
  width: 14px;
  height: 14px;

  background: var(--carousel-bullet-background, $white);
  border: 2px solid $dark;
  border-radius: 50%;
  padding: 0;
  margin: 0 0.5em;
}
.glide__bullet:hover {
  cursor: pointer;
}
.glide__bullet--active {
  background: var(--carousel-bullet-background--active, $pink-dark);
}
.glide__bullet--active:hover {
  cursor: default;
}
</style>
