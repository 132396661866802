<template>
  <PrismicLink v-if="showLink" :field="link" class="btn-primary" :class="{'has-image': (icon && icon.url)}">
    <span v-if="text">{{ text }}</span>
    <span v-else><slot /></span>
    <PrismicImage v-if="icon && icon.url" :field="icon" width="16" height="16" class="icon" />
  </PrismicLink>
</template>

<script>
/**
 * Takes either a text prop or slot content. Text prop wins over slot.
 */
export default {
  name: 'PrimaryButton',
  props: {
    link: {
      type: Object,
      required: true,
      default: () => ({
        url: ''
      })
    },
    icon: {
      type: Object,
      required: false,
      default: () => ({})
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    showLink() {
      return this.text || (!this.text && Array.isArray(this.$slots?.default));
    }
  }
};
</script>

<style scoped lang="scss">
.btn-primary {
  display: inline-flex;
  margin-block: 0.5em;
  align-items: center;
  gap: 0.5em;
  text-decoration: none !important;
}

.has-image {
  padding: 0.8rem 1rem 0.8rem 2rem;
}

.icon {
  object-fit: contain;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  padding: 5px;
}
</style>
