// Code generated by Slice Machine. DO NOT EDIT.

import AudioEmbed from './AudioEmbed';
import BlockQuote from './BlockQuote';
import CallToActionButton from './CallToActionButton';
import ContactForm from './ContactForm';
import ContentList from './ContentList';
import DynamicTable from './DynamicTable';
import HeroBanner from './HeroBanner';
import HubspotForm from './HubspotForm';
import IframeRenderer from './IframeRenderer';
import ImageGrid from './ImageGrid';
import IncludeBlock from './IncludeBlock';
import MediaSlider from './MediaSlider';
import MediaTextBlock from './MediaTextBlock';
import ProductBlock from './ProductBlock';
import ProfileCard from './ProfileCard';
import RoundedMediaBlock from './RoundedMediaBlock';
import StickyMenu from './StickyMenu';
import SubscribeDownload from './SubscribeDownload';
import TextContent from './TextContent';
import VideoBlock from './VideoBlock';

export {
	AudioEmbed,
	BlockQuote,
	CallToActionButton,
	ContactForm,
	ContentList,
	DynamicTable,
	HeroBanner,
	HubspotForm,
	IframeRenderer,
	ImageGrid,
	IncludeBlock,
	MediaSlider,
	MediaTextBlock,
	ProductBlock,
	ProfileCard,
	RoundedMediaBlock,
	StickyMenu,
	SubscribeDownload,
	TextContent,
	VideoBlock,
};

export const components = {
	audio_embed: AudioEmbed,
	block_quote: BlockQuote,
	call_to_action_button: CallToActionButton,
	contact_form: ContactForm,
	content_list: ContentList,
	dynamic_table: DynamicTable,
	hero_banner: HeroBanner,
	hubspot_form: HubspotForm,
	iframe_renderer: IframeRenderer,
	image_grid: ImageGrid,
	include_block: IncludeBlock,
	reviews_slider: MediaSlider,
	media_text_block: MediaTextBlock,
	product_block: ProductBlock,
	profile_card: ProfileCard,
	rounded_media_block: RoundedMediaBlock,
	sticky_menu: StickyMenu,
	subscribe_download: SubscribeDownload,
	text_content: TextContent,
	video_block: VideoBlock,
};
