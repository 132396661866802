<template>
  <div class="thumbnail-card">
    <img
      v-if="item.image && item.image.url"
      :src="item.image.url"
      :alt="item.alt"
      :width="item.image.width || 88"
      :height="item.image.height || 88"
      class="image"
      loading="lazy"
    >
    <PrismicRichText :field="item.text" class="text" />
    <div class="footer">
      <p v-if="item.author_label" class="author-label">
        {{ item.author_label }}
      </p>
      <p v-if="item.fullname" class="author-value">
        {{ item.fullname }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThumbnailCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.thumbnail-card {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  min-height: 268px;
  box-sizing: border-box;
  background: #FFB8D2;
  border: 2px solid #000000;
  text-align: center;
  position: relative;
}
.text {
  margin-block-start: 3em;
  margin-block-end: 1em;
  padding: 0 2em;
  font-size: 1.2rem;
  text-align: left;
  word-break: break-word;
}
.image {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 88px;
  height: 88px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid $dark;
}
.author-label {
  font-weight: bold;
  margin: 0;
}
.author-value {
  margin: 0 0 3em;
}
.footer {
  text-align: center;
}
</style>
