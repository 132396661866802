<template>
  <section :id="slice.primary.reference" class="clamped--text cms">
    <div v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
      <PrismicRichText :field="item.content" />
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';

export default {
  name: 'TextContent',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context'])
};
</script>

<style scoped lang="scss">

section div div::v-deep > *:not(.block-img) {
    max-width: calc($large - 15rem);
    margin-left: auto;
    margin-right: auto;
  }

section div div::v-deep p > img {
    border-radius: 1.25rem;
  }

</style>
