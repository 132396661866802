<template>
  <section :id="slice.primary.reference" class="section clamped">
    <div v-if="!error.message && product.sku" class="product" :class="{'is-first': isFirstSlice}">
      <div class="product__gallery">
        <div class="sf-gallery__stage">
          <div class="glide__slide">
            <LazyHydrate when-idle>
              <nuxt-img
                :src="imageUrl"
                :alt="imageAlt"
                :height="imageHeight"
                :width="imageWidth"
                :placeholder="85"
                quality="80"
                preload
                class="product__image"
              />
            </LazyHydrate>
          </div>
        </div>
      </div>
      <div class="product__info">
        <div class="product__header">
          <LazyHydrate never>
            <SfHeading
              :title="getProductName(product)"
              :level="1"
              class="sf-heading--no-underline sf-heading--left"
            />
          </LazyHydrate>
        </div>
        <div>
          <LazyHydrate when-idle>
            <component
              :is="renderer"
              v-if="product && product.sku"
              :product="product"
              :is-fetching="loading"
              @fetchProduct="fetchProduct($event.query)"
              @cartStatus="hasUpdatedStatus"
            >
              <template #after-price>
                <CurrencySelector
                  v-if="hasCurrencyToSelect"
                  class="product__currency"
                  :button-class="{'product__currency-selector sf-button--pure': true}"
                >
                  (change currency)
                </CurrencySelector>
              </template>

              <template #add-cart-button-contents>
                <span v-if="!cartSuccess">{{ $t('Add to cart') }}</span>
                <span v-else>{{ $t('Success!') }}</span>
              </template>

              <template #additional-actions>
                <div class="product__additional-actions">
                  <div class="product__additional-actions">
                    <AddToWishlist
                      :is-in-wishlist="isInWishlist"
                      :is-show="isAuthenticated"
                      @addToWishlist="addItemToWishlist({product})"
                    />
                    <SfButton
                      v-if="cartSuccess"
                      class="sf-button--pure product__action product__action--feature"
                      aria-label="Toggle cart sidebar"
                      @click="toggleCartSidebar"
                    >
                      <span>
                        {{ $t('View cart and Checkout') }}&nbsp;
                      </span>
                      <SfIcon
                        size="24px"
                        color="dark-secondary"
                        icon="arrow_right"
                        class="product__icon"
                      />
                    </SfButton>
                  </div>
                </div>
              </template>
            </component>
          </LazyHydrate>

          <LazyHydrate never>
            <HTMLContent
              v-if="shortDescription"
              :content="shortDescription"
              tag="div"
              class="product__description"
            />
          </LazyHydrate>
        </div>
      </div>
    </div>
    <div v-else-if="!error.message && !product.sku">
      <p class="loading">
        Loading this product...
      </p>
    </div>
    <div v-else>
      <p class="explanation">
        We were unable to load this product.
      </p>
    </div>

    <div class="factors">
      <SfCharacteristic
        v-for="characteristic in characteristics"
        :key="characteristic.title"
        :title="characteristic.title"
        :description="characteristic.description"
        :icon="characteristic.icon"
        class="characteristic"
      />
    </div>
  </section>
</template>

<script>
import {
  SfButton,
  SfHeading,
  SfIcon,
  SfCharacteristic
} from '@storefront-ui/vue';
import { getSliceComponentProps } from '@prismicio/vue/components';
import { ref } from '@nuxtjs/composition-api';
import { merge } from 'lodash-es';
import LazyHydrate from 'vue-lazy-hydration';
import { ProductTypeEnum } from '@/modules/catalog/product/enums/ProductTypeEnum';
import { useApi, useProduct, useUiState, useUser } from '@/composables';
import { Logger } from '@/helpers/logger';
import getProductPriceBySkuGql from '@/modules/catalog/product/queries/getProductPriceBySku.gql';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import {
  getName as getProductName,
} from '~/modules/catalog/product/getters/productGetters';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import useTopBar from '~/components/TopBar/useTopBar';
import ResizedImage from '~/components/General/ResizedImage';

const CHARACTERISTICS = [
  {
    title: 'Trusted by the community',
    description: 'We have helped thousands achieve new heights in their careers.',
    icon: 'profile'
  },
  {
    title: 'High quality material',
    description: 'Don\'t "just" get certified, instead, learn the "why" and the "how" behind these techniques.',
    icon: 'rewards'
  },
  {
    title: 'Easy payment',
    description: 'We accept Paypal and credit cards and our products are available in many different currencies.',
    icon: 'credits'
  }
];

export default {
  name: 'ProductBlock',
  components: {
    ResizedImage,
    LazyHydrate,
    SfIcon,
    SfButton,
    SfHeading,
    SfCharacteristic,
    HTMLContent: () => import('~/components/HTMLContent.vue'),
    AddToWishlist: () => import('~/components/AddToWishlist.vue'),
    SimpleProduct: () => import('~/modules/catalog/product/components/product-types/simple/SimpleProduct.vue'),
    ConfigurableProduct: () => import('~/modules/catalog/product/components/product-types/configurable/ConfigurableProduct.vue'),
    TestProduct: () => import('~/modules/catalog/product/components/product-types/test/TestProduct.vue'),
    DownloadableProduct: () => import('~/modules/catalog/product/components/product-types/downloadable/DownloadableProduct.vue'),
    BundleProduct: () => import('~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue'),
    GroupedProduct: () => import('~/modules/catalog/product/components/product-types/grouped/GroupedProduct.vue'),
    ErrorPage: () => import('~/layouts/error.vue'),
    CurrencySelector: () => import('~/components/CurrencySelector.vue')
  },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup() {
    const { query } = useApi();
    const product = ref(null);
    const { getProductDetails, loading } = useProduct();
    const { productGallery, imageSizes } = useProductGallery(product);
    const error = ref({});
    const { hasCurrencyToSelect } = useTopBar();
    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist: isInWishlistLookup } = useWishlist();
    const { toggleCartSidebar } = useUiState();

    return {
      query,
      error,
      loading,
      getProductName,
      productGallery,
      imageSizes,
      isInWishlistLookup,
      addItemToWishlist: addOrRemoveItem,
      getProductDetails,
      hasCurrencyToSelect,
      isAuthenticated,
      toggleCartSidebar,
      characteristics: CHARACTERISTICS,
    };
  },
  data() {
    return {
      product: {},
      productImage: {},
      cartSuccess: false,
    };
  },
  fetchKey: 'product-block',
  async fetch() {
    Logger.info('Running fetch');

    const fetchProductBaseData = async (searchQuery = this.getBaseSearchQuery()) => {
      let result = null;
      try {
        result = await this.getProductDetails({
          ...searchQuery,
        });

        this.product = merge({}, this.product, result?.items[0] ?? null);

        if (!result) {
          throw new Error('We were unable to find this product.');
        }
      } catch (e) {
        Logger.error(e?.message);

        this.error = {
          statusCode: 404,
          message: e?.message ?? 'We can\'t find this product'
        };
      }
    };

    await fetchProductBaseData();

    if (Boolean(this.product?.sku) === false) {
      throw new Error('Unable to find product.');
    }

    if (!this.productImage?.desktop && this.product?.image?.url) {
      this.productImage = {
        alt: this.product.image.label,
        desktop: {
          url: this.product.image.url
        }
      };
    }
  },
  computed: {
    isFirstSlice() {
      return this.index === 0;
    },
    renderer() {
      return this.product?.__typename ?? ProductTypeEnum.SIMPLE_PRODUCT;
    },
    shortDescription() {
      return this.product?.short_description?.html || '';
    },
    imageUrl() {
      return this.productImage?.desktop?.url || '/icons/image.svg';
    },
    imageAlt() {
      return this.productImage?.alt || 'Loading...';
    },
    imageHeight() {
      return this.imageSizes?.productGallery?.imageHeight || 1000;
    },
    imageWidth() {
      return this.imageSizes?.productGallery?.imageWidth || 1000;
    },
    isInWishlist() {
      return this.isInWishlistLookup({ product: this.product });
    }
  },
  watch: {
    productGallery(newValue, oldValue) {
      if (newValue?.[0]?.desktop?.url && newValue?.[0]?.desktop?.url !== oldValue?.[0]?.desktop?.url) {
        this.productImage = newValue[0];
      }
    }
  },
  mounted() {
    this.fetchProduct(this.getBaseSearchQuery(), true);
  },
  methods: {
    hasUpdatedStatus({ success }) {
      if (success) {
        this.cartSuccess = true;

        setTimeout(() => { this.cartSuccess = false; }, 12000);
      }
    },
    async fetchProduct(searchQuery = this.getBaseSearchQuery(), isInitialRun = false) {
      const { data } = await this.query(getProductPriceBySkuGql, searchQuery);

      if (isInitialRun && data && data.products?.items?.[0]?.configurable_product_options_selection?.media_gallery) {
        // prevent swapping picture on page load
        data.products.items[0].configurable_product_options_selection.media_gallery = [];
      }

      this.product = merge({}, this.product, data.products?.items?.[0]);
    },
    getBaseSearchQuery() {
      if (!this.slice.primary.sku) {
        throw new Error('Unable to locate product');
      }

      return {
        filter: {
          sku: {
            eq: this.slice.primary.sku
          },
        },
        configurations: Object.entries(this.$route.query)
          .filter((config) => config[0] !== 'wishlist')
          .map((config) => config[1]),
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.product {
  padding: 1rem 0 2rem;
  --product-gap: min(6rem, 6vmin, 5%);

  box-sizing: border-box;
  position: relative;
  margin-block-start: 3rem;

  --gallery-thumbs-display: none;
  --gallery-stage-width: 30rem;

  @include for-desktop {
    max-width: 1300px;
    margin-inline: auto;
    padding: 0 1.5rem;

    display: flex;
    gap: var(--product-gap);
    margin: 2rem 0 clamp(2rem, 5vw, 4rem);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: #{calc(var(--spacer-base) * -1)};
    left: 0;
    bottom: 25%;
    width: 100%;
    background-color: $color-purple;
    border-radius: 2em;
    z-index: -5;

    @media (min-width: $large) {
      width: calc(50% + 290px);
      position: absolute;
      left: 0;

      .product-wrap {
        margin: 2rem 0 clamp(2rem, 5vw, 4rem);
      }
    }
  }
}

.breadcrumbs {
  margin: 0.5em auto;
  --c-text-muted: $navy;

  a:hover {
    color: $color-primary;
  }
}

.product-secondary {
  margin-block-start: calc(4vh + var(--spacer-2xs));
}

.product__gallery {
  flex-basis: 50%;
  align-items: center;
  --gallery-stage-width: min(32rem, 80vw);
}

.product__image {
  height: min(40vmin, 500px);
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.product__info {
  margin: 1em auto auto;
  background-color: $white;
  border: 2px solid $dark;
  padding: max(2em, 5%);
  border-radius: 2em;
  align-self: flex-start;
  flex-basis: calc(50% - var(--product-gap));

  @include for-desktop($large) {
    margin: 0;
  }
}

.product__header {
  --heading-title-color: var(--c-link);
  --heading-title-font-weight: var(--font-weight--bold);
  --heading-padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;

  @include for-desktop($large) {
    --heading-title-font-weight: var(--font-weight--semibold);
    margin: 0 auto;
  }
}

.product .sf-heading__title {
  font-size: clamp(1.5rem, 2.5vmin, 2.75rem);
  line-height: 1.2;
  color: $color-primary;
}

.product__additional-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  margin: 0;
  width: 100%;
}

.product__action--feature {
  display: flex;
  gap: 0.5em;
  color: $link-color;
}

// Card stacking
.product__gallery::v-deep .sf-gallery__stage {
  position: relative;
  background-color: $white;
  margin: 0 auto 2rem;
  --bar-size: clamp(1em, 4vmin, 2.5em);
  --border-color: #{$orange};
  --border-size: 3px;
  --card-offset: 16px;

  box-shadow: var(--bar-size) 0 0 var(--border-color) inset,
  calc(var(--bar-size) + var(--border-size)) 0 0 $dark inset,
  0 0 0 var(--border-size) $dark;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% - var(--border-size));
    transform: scaleX(96%);
    width: 100%;
    transform-origin: top;
    height: var(--card-offset);
    z-index: -1;
    background-color: $white;
    box-shadow: var(--bar-size) 0 0 var(--border-color) inset,
    calc(var(--bar-size) + var(--border-size)) 0 0 $dark inset,
    0 0 0 var(--border-size) $dark;
  }

  &::after {
    z-index: -2;
    top: calc(100% + 8px); // magic number based on visual balance
    transform: scaleX(90%);
  }
}

.product__gallery::v-deep .sf-gallery {
  justify-content: center;
}

.product__gallery::v-deep .glide__slide {
  display: grid;
  place-content: center;
  padding: 2em 2em 2em calc(var(--bar-size) + 2em);
}

.product__gallery::v-deep .sf-gallery__stage {
  margin-bottom: 3em;
  width: 100%;
}

.product__currency::v-deep .product__currency-selector {
  color: var(--color-blue);
  font-family: var(--font-family--primary);
  font-weight: normal;
  font-size: .8em;
}

.explanation {
  padding: 3em;
  text-align: center;
}

.factors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  gap: 1em;
  padding-block-end: 2em;
}

.characteristic {
  width: max(28%, 300px);
}

.characteristic::v-deep > div {
  --icon-color: var(--color-blue);
}

.loading {
  place-content: center;
  text-align: center;
  padding: 200px 0;
}
</style>
