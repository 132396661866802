<template>
  <div
    :id="slice.primary.reference"
    ref="stickyMenu"
    class="sticky-menu"
    :style="{'marginTop': `${Number(slice.primary.space_above)}rem`}"
  >
    <div class="menu-container">
      <ul class="menu">
        <li v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
          <a
            :href="`${$route.path}#${item.block_reference}`"
            class="link"
            :class="{'is-active': item.block_reference === activeItem}"
            @click="goToTarget($event, item.block_reference)"
          >
            {{ lookupMenuTitleFor(item.block_reference ) }}
          </a>
        </li>
      </ul>

      <div v-if="(slice.primary.right_text && slice.primary.right_text.length > 0) || showLink" class="trailer">
        <PrismicRichText v-if="slice.primary.right_text" :field="slice.primary.right_text" class="extra-text" />
        <PrismicLink v-if="showLink" :field="slice.primary.extra_link_primary" class="extra-link btn-primary">
          {{ slice.primary.extra_link_primary_label }}
        </PrismicLink>
      </div>
    </div>
  </div>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import zenscroll from 'zenscroll';
import { Logger } from '@/helpers/logger';

export default {
  name: 'StickyMenu',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      preventSwitching: false,
      activeItem: this.$route.hash?.substring(1) || this.slice.items[0]?.block_reference,
      currentReference: null,
    };
  },
  computed: {
    showLink() {
      return this.slice.primary.extra_link_primary?.url && this.slice.primary.extra_link_primary_label;
    }
  },

  mounted () {
    this.watchReferences();
    zenscroll.setup(null, this.getOffset());
  },
  methods: {
    lookupMenuTitleFor(reference) {
      const blockMatch = this.slices?.find(item => item.primary.reference?.toLowerCase()?.trim() === reference?.toLowerCase()?.trim());
      let result = blockMatch?.primary?.menu_title || blockMatch?.primary?.title || blockMatch?.primary?.name;

      if (!result) {
        Logger.error('Missing Menu Title for block ', reference);
      }

      if (typeof result !== 'string') {
        try {
          result = this.$prismic.asText(result);
        } catch (e) {
          Logger.error('Failed to render menu title', result, e);
          result = '';
        }
      }

      return result;
    },
    goToTarget(event, item) {
      this.preventSwitching = true;
      event.stopPropagation();
      event.preventDefault();

      this.activeItem = item;
      const target = document.getElementById(item);

      if (!target) {
        Logger.warn('Cannot find element');
        return;
      }

      zenscroll.toY(zenscroll.getTopOf(target) - this.getOffset() + 2, null, () => {
        setTimeout(() => { this.preventSwitching = false; }, 50);
      });
    },
    getOffset() {
      return this.$refs.stickyMenu?.getBoundingClientRect().height;
    },

    watchReferences() {
      const intersectionOptions = {
        rootMargin: `-${this.getOffset()}px 0px 0px 0px`,
        threshold: [0, 0.25, 0.5, 0.75, 1]
      };

      const observer = new IntersectionObserver(this.referencePositionChange.bind(this), intersectionOptions);
      this.slice.items?.forEach(id => {
        const target = document.getElementById(id.block_reference);
        if (target) {
          observer.observe(target);
        }
      });
    },

    referencePositionChange(entries) {
      const visibleEntries = entries.filter(entry => entry.isIntersecting)
        .sort((a, b) => a.boundingClientRect.bottom - b.boundingClientRect.bottom);

      const firstVisible = visibleEntries.length > 0 ? visibleEntries[0] : undefined;

      if (!this.preventSwitching && firstVisible && !this.referenceAlreadyVisible(firstVisible)) {
        this.activeItem = firstVisible.target.id;
        this.currentReference = firstVisible.target;
      }

      if (!firstVisible && this.visibleReferences && this.visibleReferences.length > 0) {
        this.referencePositionChange(this.visibleReferences);
      }
    },

    referenceAlreadyVisible(firstVisible) {
      if (!this.currentReference) { return false; }
      const currentRect = this.currentReference.getBoundingClientRect();

      return currentRect.top < firstVisible.boundingClientRect.top &&
        currentRect.bottom > this.getOffset();
    },
  }
};
</script>

<style scoped lang="scss">
.sticky-menu {
  position: sticky;
  top: 0;
  z-index: 25;
  font-size: clamp(0.85em, 2.1vw, 1.15em);
  filter: drop-shadow(-15px 0 15px white) drop-shadow(15px -10px 15px white);
  margin-bottom: var(--spacer-lg);
  display: flex;
  justify-content: center;
}

.menu-container {
  background-color: $color-light-gray;
  border-radius: 16px;
  flex-wrap: wrap-reverse;
  padding: 0.5rem;
}

.menu-container,
.menu,
.trailer {
  display: flex;
  align-items: flex-end;
  gap: min(1em, 1vw);
}

.menu {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.link {
  padding: 0.75em min(1.5em, 1.5vw);
  color: $navy;
  display: block;
}

.is-active {
  background-color: $white;
  border: 2px solid $black;
  border-radius: 8px;
  font-weight: bold;
}

.trailer {
  margin-left: auto;
}

.extra-text {
  padding: 0.75em;
}

.extra-text ::v-deep p {
  margin: 0;
  line-height: normal;
}

.extra-link {
  padding: 0.5em min(1em, 1vw);
  display: block;
  border-radius: 4px;
  margin-block: 2px;
}
</style>
