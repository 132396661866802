<template>
  <iframe
    ref="iframe"
    class="iframe clamped"
    :src="slice.primary.src.url"
    :width="slice.primary.maxWidth || '100%'"
    :height="slice.primary.height || 'auto'"
  />
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';

export default {
  name: 'IframeRenderer',
  props: getSliceComponentProps(['slice'])
};
</script>

<style scoped lang="scss">
.iframe {
  border: 0;
  display: flex;
}
</style>
