<template>
  <div :id="slice.primary.reference">
    <component
      :is="heroComponent"
      :image="slice.primary.image"
      :background_color="slice.primary.background_color"
      :image-on-right="slice.primary.image_on_right"
      :round-top-corners="slice.primary.roundTopCorners"
      :lazy="index > 1"
      class="section"
    >
      <PrismicRichText :field="slice.primary.feature_text" class="hero-text title" />
      <PrismicRichText :field="slice.primary.secondary_text" class="hero-text secondary-text" />
      <PrimaryButton
        v-if="link.url"
        :link="link"
        :text="$prismic.asText(slice.primary.link_text)"
        :icon="slice.primary.link_icon"
      />
    </component>
  </div>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import PrimaryButton from '@/components/General/PrimaryButton';
import SideBySideHeroImage from '~/components/Media/Hero/SideBySideHeroImage.vue';
import DefaultHeroImage from '~/components/Media/Hero/DefaultHeroImage.vue';

export default {
  name: 'StandardHero',
  components: {
    PrimaryButton,
    SideBySideHeroImage,
    DefaultHeroImage
  },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    heroComponent () {
      return (this.slice.variation === 'sideBySide')
        ? SideBySideHeroImage
        : DefaultHeroImage;
    },
    link () {
      if (typeof this.slice.primary.link === 'string') {
        return {
          url: this.slice.primary.link
        };
      } else {
        return this.slice.primary.link;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 1.75em;

  ::v-deep > * {
    font-size: clamp(2rem, 2vmin + 1.5rem, 3.5rem);
    line-height: 1;
  }
}

.secondary-text {
  margin-bottom: 1.75em;
  font-size: clamp($font-size-base, 1vw + $font-size-base, $font-size-up-1);
}

.btn-primary:not(:hover) {
  color: inherit;
}
</style>
