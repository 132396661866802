<template>
  <section class="section">
    <PrismicRichText :field="slice.primary.title" class="title" />
    <PrismicRichText :field="slice.primary.description" />
    <PrismicEmbed :field="slice.primary.media_file.html" />
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';

export default {
  name: 'AudioEmbed',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
};
</script>

<style scoped>
.title {
  margin-bottom: 1rem;
}
</style>
