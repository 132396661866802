<template>
  <div :id="slice.primary.reference" class="clamped wrapper">
    <div id="hubspotForm" ref="hubspotForm" />
  </div>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
export default {
  name: 'HubspotForm',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  mounted() {
    this.createHubSpotForm();
  },
  methods: {
    createHubSpotForm() {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.async = true;

      script.addEventListener('load', () => {
        const formConfig = {
          region: this.slice.primary.region_id || process.env.HUBSPOT_CONTACT_REGION_ID,
          portalId: this.slice.primary.portal_id || process.env.HUBSPOT_CONTACT_FORM_PORTAL_ID,
          formId: this.slice.primary.form_id || process.env.HUBSPOT_CONTACT_FORM_ID,
          target: '#hubspotForm'
        };
        window.hbspt.forms.create(formConfig);
      });

      document.body.appendChild(script);
    }
  }
};
</script>
<style scoped lang="scss">
.wrapper {
  padding-block: 1rem;
  min-height: 300px;
  max-width: calc($large - 15rem);
}
</style>
