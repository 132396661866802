






















































import {
  SfSection,
  SfButton
} from '@storefront-ui/vue';

import { defineComponent, ref } from '@nuxtjs/composition-api';
import ThumbnailCard from '~/components/Media/Cards/ThumbnailCard.vue';
import CustomCarousel from '~/components/Media/Carousel/CustomCarousel.vue';
import StarIcon from '~/assets/icons/star.svg?inline';
import ArrowRightIcon from '~/assets/icons/arrow-right.svg?inline';
import ArrowLeftIcon from '~/assets/icons/arrow-left.svg?inline';

export default defineComponent({
  name: 'MediaCarousel',
  components: {
    ThumbnailCard,
    CustomCarousel,
    SfSection,
    SfButton,
    StarIcon,
    ArrowLeftIcon,
    ArrowRightIcon
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    variant: {
      type: String,
      required: false,
      default: 'default'
    },
    options: {
      type: Object,
      required: false,
      default () {
        return {};
      }
    },
    loading: Boolean,
    items: {
      type: Array,
      required: false,
      default () {
        return [];
      }
    }
  },
  setup (props) {
    const defaultSettings = {
      gap: 10,
      peek: 0,
      perView: 3,
      breakpoints: {
        767: { gap: 0, perView: 1 },
        1023: { peek: 0, perView: 2, gap: 20 }
      }
    };
    const starSizes = ref({});
    const carouselSettings = ref(Object.keys(props?.options).length > 0
      ? {
          ...defaultSettings,
          ...props.options
        }
      : defaultSettings);

    const starColors = [
      'var(--color-blue)',
      'var(--color-pink-dark)',
      'var(--color-purple)',
      'var(--color-white)',
      'var(--color-yellow)',
      'var(--color-orange)'
    ];

    return {
      carouselSettings,
      starColors,
      starSizes
    };
  },
  methods: {
    getTranslate (name, value) {
      if (['left', 'right'].includes(name)) {
        return `
            ${value > 50 ? 'translateY(50%)' : 'translateY(-50%)'}
             translateX(${name === 'left' ? '-' : ''}50%)
          `;
      }
      return `
            ${value > 50 ? 'translateX(50%)' : 'translateX(-50%)'}
             translateY(${name === 'top' ? '-' : ''}50%)
          `;
    },
    getSide () {
      const sides = [
        {
          name: 'top',
          inline: {
            top: 0,
            bottom: 'auto',
            right: 'auto'
          },
          options: {
            change: 'left'
          }
        },
        {
          name: 'right',
          inline: {
            left: 'auto',
            bottom: 'auto',
            right: 0
          },
          options: {
            change: 'top'
          }
        },
        {
          name: 'bottom',
          inline: {
            right: 'auto',
            top: 'auto',
            bottom: 0
          },
          options: {
            change: 'left'
          }
        },
        {
          name: 'left',
          inline: {
            right: 'auto',
            bottom: 'auto',
            left: 0
          },
          options: {
            change: 'top'
          }
        }
      ];

      const randomSide = sides[this.randomNumber(0, 3)];
      let position = this.randomNumber(10, 90);
      const adjustPositionFor = ['left', 'right', 'top'];
      // avoid stars on image/arrow
      while ((position > 30) && (position < 70) && adjustPositionFor.includes(randomSide.options.change)) {
        position = position <= 70 && position >= 30 ? (position - 5) : (position + 5);
      }
      randomSide.inline[randomSide.options.change] = `${position}%`;
      return randomSide;
    },

    randomNumber (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    setStarSizes (i) {
      if (this.starSizes[i]) {
        return this.starSizes[i];
      }

      this.starSizes[i] = `${this.randomNumber(16, 52)}px`;
    },
    starStyles (i) {
      this.setStarSizes(i);
      const side = this.getSide();
      const starStyle = {
        ...side.inline,
        color: this.starColors[this.randomNumber(0, this.starColors.length - 1)]
      };

      return {
        ...starStyle,
        transform: `
          ${this.getTranslate(side.name, side.inline[side.options.change])}
          rotate(${this.randomNumber(0, 180)}deg)
        `,
        [side.name]: 'var(--star-container-padding)'
      };
    }
  }
});
