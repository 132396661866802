<template>
  <section class="action-block">
    <PrimaryButton :link="slice.primary.link_destination" :text="$prismic.asText(slice.primary.link_text)" :icon="slice.primary.icon" />
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import PrimaryButton from '@/components/General/PrimaryButton';

export default {
  name: 'CallToActionButton',
  components: { PrimaryButton },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
};
</script>

<style scoped>
.action-block {
  display: flex;
  place-content: center;
  padding: min(2em, 4vmin);
}
</style>
