<template>
  <section :id="slice.primary.reference" class="clamped">
    <PrismicRichText :field="slice.primary.title" class="heading" />
    <div class="gallery" :style="{'--max-width': maxWidth, '--max-height': maxHeight}">
      <div
        v-for="(item, i) in slice.items"
        :key="`slice-item-${i}`"
        class="gallery-item"
      >
        <PrismicLink v-if="item.link && item.link.url" :field="item.link" :rel="slice.primary.no_follow === false ? '' : 'nofollow noreferrer noopener'">
          <ResizedImage
            :srcset="item.image"
            :field="item.image"
            :width="maxWidthInteger"
            :height="maxHeightInteger"
            :loading="slice.primary.lazy_load ? 'lazy' : 'eager'"
            :alt="item.image.alt || 'Company Logo'"
          />
        </PrismicLink>

        <ResizedImage
          v-else
          :srcset="item.image"
          :field="item.image"
          :width="maxWidthInteger"
          :height="maxHeightInteger"
          :loading="slice.primary.lazy_load ? 'lazy' : 'eager'"
          :alt="item.image.alt || 'Company Logo'"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import ResizedImage from "~/components/General/ResizedImage";

export default {
  name: 'ImageGrid',
  components: {ResizedImage},
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    maxWidthInteger() {
      return this.getNumberFrom(this.slice.primary.max_width) || 200;
    },
    maxHeightInteger() {
      return this.getNumberFrom(this.slice.primary.max_height) || this.maxWidthInteger || 200;
    },
    maxWidth () {
      const width = this.slice.primary.max_width;
      return Number(width) ? `${width}px` : width;
    },
    maxHeight () {
      const height = this.slice.primary.max_height;
      return Number(height) ? `${height}px` : height;
    }
  },
  methods: {
    getNumberFrom(field) {
      let value = field;

      if (typeof field === 'string') {
        value = value?.replace('px', ' ');
      }

      if (Number.isFinite(value)) {
        return Number(value);
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.clamped {
  padding-block-start: calc(1em + 1vmin);
  padding-block-end: calc(1em + 1vmin);
  text-align: center;
}

.heading {
  margin-bottom: 1em;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(45%, var(--max-width, 185px)), 1fr));
  align-items: center;
  justify-content: space-between;
  gap: calc(2% + 1em);
  grid-auto-rows: minmax(100px, auto);
}

.gallery-item img {
  max-height: var(--max-height, 150px);
  object-fit: contain;
}
</style>
