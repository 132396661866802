<template>
  <section :id="slice.primary.reference"
           class="clamped dynamic-table"
           :class="{'comparison': comparison}">
    <PrismicRichText :field="slice.primary.title" class="title" />
    <PrismicRichText :field="slice.primary.description" />

    <div class="overflow">
      <table>
        <thead v-if="showHeaderRow">
          <tr>
            <th v-for="i in columnArray" :key="`label-${i}`" scope="col">
              <span v-html="$prismic.asHtml(slice.primary[`label_${i}`])"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowNum) in slice.items" :key="rowNum">
            <template v-for="i in columnArray">
              <th v-if="comparison && i === 1" :key="`${rowNum}-column-${i}`" scope="row">
                <span v-html="$prismic.asHtml(row[`column_1`])" class="title"></span>
                <span v-if="row['column_1_explanation']" v-text="row['column_1_explanation']" class="explanation"></span>
              </th>
              <td v-else :key="`${rowNum}-column-${i}`">
                <template v-if="comparison">
                  <div>
                    <span v-if="row['type'] === 'Stars'" class="stars">
                      <template v-if="$prismic.asText(row[`column_${i}`]) === 'X'"><b>X</b></template>
                      <template v-else v-for="n in 5">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             v-if="n <= $prismic.asText(row[`column_${i}`])"
                             :key="n"
                             x="0px"
                             y="0px"
                             width="18"
                             height="18"
                             viewBox="0 0 24 24"
                             class="used">
                            <path d="M12,17.877L18.831,22l-1.813-7.77l6.035-5.228l-7.947-0.674L12,1L8.894,8.328L0.947,9.002l6.035,5.228L5.169,22L12,17.877z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             :key="n"
                             v-else
                             x="0px"
                             y="0px"
                             width="18"
                             height="18"
                             viewBox="0 0 24 24">
                            <path d="M23.04,9.021L14.77,8.796L12,1L9.23,8.796L0.96,9.021l6.559,5.043L5.177,22L12,17.321L18.823,22l-2.342-7.935L23.04,9.021z M12,14.896l-3.312,2.271l1.137-3.851l-3.183-2.448l4.014-0.109L12,6.974l1.344,3.784l4.014,0.109l-3.183,2.448l1.137,3.851 L12,14.896z"></path>
                        </svg>
                      </template>
                    </span>
                    <span v-else-if="row['type'] === 'Dollars'"
                          v-for="n in 5"
                          class="dollars"
                          :class="{used: n <= $prismic.asText(row[`column_${i}`])}"
                          :key="n">$</span>
                    <span v-else><span v-html="$prismic.asHtml(row[`column_${i}`])"></span></span>
                  </div>
                  <div v-if="row[`column_${i}_explanation`]" v-text="row[`column_${i}_explanation`]" class="explanation"></div>
                </template>
                <span v-else v-html="$prismic.asHtml(row[`column_${i}`])" ></span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
export default {
  name: 'DynamicTable',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    comparison() {
      return this.slice.variation === 'comparison';
    },
    showHeaderRow() {
      let showHeaderRow = false;

      this.columnArray.forEach(index => {
        if (this.slice.primary[`label_${index}`]?.[0]) {
          showHeaderRow = true;
        }
      });

      return showHeaderRow;
    },
    columnArray() {
      const arrayIndex = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      let maxIndex = 0;

      for (const i in this.slice.items) {
        arrayIndex.forEach(index => {
          if (this.slice.items[i][`column_${index}`]?.[0] && index > maxIndex) {
            maxIndex = index;
          }
        });
      }

      return arrayIndex.slice(0, maxIndex);
    }
  }
};
</script>

<style scoped lang="scss">
.dynamic-table {
  padding-block: 1em;
}

.overflow {
  overflow: auto;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  text-align: left;
  margin: 0 auto;
  position: relative;
}

.comparison table {
  width: $large + 10rem;
}

@media (max-width: $large + 10rem) {
  .comparison table {
    width: auto;
  }
}

thead {

}

thead th {
  border-block-end: 2px solid var(--black);
  padding: 0.5em;

  background: white;
  position: sticky;
  z-index: 10;
  top: 0;
}

.comparison td, .comparison th {
  min-width: 125px;
  text-align: center;
}

.comparison tbody th {
  text-align: right;
}

tbody th {
  padding: 1em;
  font-weight: normal;
  max-width: 250px;
}

.title >>> p {
  margin-bottom: 0;
}

.explanation {
  font-size: 0.75rem;
}

td {
  padding: 1em;
  text-align: center;
}

.stars svg, .dollars {
  color: lighten($navy, 20);
  fill: lighten($navy, 20);
}

.stars svg.used, .dollars.used {
  color: var(--c-secondary);
  fill: var(--c-secondary);
  font-weight: bold;
}

tbody tr:nth-child(even) > td, tbody tr:nth-child(even) > th {
  background-color: var(--color-background);

  &:first-child {
    border-radius: 16px 0 0 16px;
  }

  &:last-child {
    border-radius: 0 16px 16px 0;
  }
}
</style>
