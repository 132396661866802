<template>
  <SliceZone
    v-if="content.data && content.data[sliceKey]"
    :components="components"
    :slices="content.data[sliceKey]"
    class="feature"
  />
</template>

<script>
import { components } from '@/slices';

export default {
  name: 'CategoryFeature',
  props: {
    sliceKey: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      components
    };
  },
};
</script>

<style scoped>

</style>
