<template>
  <div :id="slice.primary.reference" class="clamped clamped--carousel">
    <MediaCarousel
      v-if="slice.items && slice.items.length > 0"
      :items="slice.items"
      class="media-carousel"
      :options="getOptions(slice.primary)"
    />
  </div>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import MediaCarousel from '@/components/Media/Carousel/MediaCarousel';

export default {
  name: 'ReviewsSlider',
  components: { MediaCarousel },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  methods: {
    getOptions (data) {
      return {
        perView: data?.columns ?? 3,
        perViewTablet: data?.columnsTablet ?? 2,
        perViewMobile: data?.columnsMobile ?? 1
      };
    }
  }
};
</script>

<style lang="scss">
@include for-desktop($desktop-xl-min) {
  /* because of carousel arrows */
  .clamped--carousel {
    padding: 0;
  }
}
</style>
