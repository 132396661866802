<template>
  <section :id="slice.primary.reference" class="section">
    <div class="clamped">
      <PrismicRichText :field="slice.primary.title" class="title" />
      <PrismicRichText :field="slice.primary.description" />
    </div>
    <SkeletonLoader v-if="loading" height="min(400px, 30vh)" width="100%" />
    <CmsListContainer v-else :list="items" />
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import { onMounted, ref, useContext } from '@nuxtjs/composition-api';
import useCms from '@/prismic/composables/useCmsContent';
import CmsListContainer from '@/components/List/CmsListContainer';
import SkeletonLoader from '@/components/SkeletonLoader';
import { Logger } from '@/helpers/logger';
import { MAIN_CATEGORY, SUB_CATEGORY } from '@/prismic/const';

export default {
  name: 'ContentList',
  components: { CmsListContainer, SkeletonLoader },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup({ slice }) {
    const context = useContext();
    const { queryLinkedList } = useCms(context.$prismic);
    const items = ref({});
    const error = ref('');
    const loading = ref(true);

    onMounted(async () => {
      Logger.debug('CATEGORY', slice.primary.category);

      const response = await queryLinkedList({
        type: 'page',
        id: slice.primary.category?.id,
        categoryType: slice.primary.sub_category_type ? SUB_CATEGORY : MAIN_CATEGORY,
        tags: (slice.primary.tag_filter)?.split(',')?.map(tag => `${tag}`.trim())
      });

      items.value = response?.content?.value?.results;
      loading.value = response.loading?.value;
      error.value = response.error?.value;

      Logger.debug(items.value);

      // Convenient logger for seeing sorting info
      //       Logger.info(...items.value.map(item => {
      //         return `
      // Title: ${item.data.title}
      // First: ${(new Date(item.first_publication_date)).toString()}
      // Override: ${item.data?.published_override ? (new Date(item.data.published_override)).toString() : '--'}
      //         `;
      //       }));
    });

    return {
      items,
      loading,
      error
    };
  },
};
</script>

<style scoped>
.section {
  margin-block: var(--spacer-base);
}
</style>
