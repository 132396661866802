import { ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { MAIN_CATEGORY } from '~/prismic/const';
import map from '~/slices/map';

export default function useCmsContent ($prismic) {
  if (!$prismic) {
    Logger.error('Please provide $prismic');
  }

  return {
    async search ({ type, field, key }) {
      const content = ref({});
      const error = ref('');
      const loading = ref(true);

      try {
        if (!field) {
          content.value = await $prismic.api.getByUID(type, key);
        } else {
          content.value = await $prismic.api.queryFirst($prismic.predicate.at(`my.${type}.${field}`, key));
        }
      } catch (e) {
        const errorMessage = typeof e === 'string' ? e : e?.message || 'See log for details.';
        Logger.error(`PRISMIC: Failed to get data for type ${type} and field ${field}. (Key: ${key})`, e);
        error.value = errorMessage;
      }

      loading.value = false;

      return {
        content,
        error,
        loading
      };
    },

    async queryOne({ slug, type = 'page' }) {
      const content = ref({});
      const error = ref('');
      const loading = ref(true);

      // Could move this more of a config file somewhere
      const overrideQuery = {
        card_list: `
            ...on card_list {
              variation {
                ...on default {
                  primary {
                    ...primaryFields
                  }
                  items {
                    ...itemsFields
                    page_link {
                      ...on page {
                        title
                        image
                        subtitle
                        summary
                      }
                    }
                  }
                }
              }
            }
        `,
        hero_banner: `
          ...on hero_banner {
              variation {
                ...on default {
                  primary {
                    ...primaryFields
                  }
                  items {
                    ...itemsFields
                  }
                }
                ...on sideBySide {
                  primary {
                    ...primaryFields
                  }
                  items {
                    ...itemsFields
                  }
                }
              }
            }
        `
      };

      const sliceQuery = Object.keys(map).reduce((carry, key) => {
        if (overrideQuery[key]) {
          return carry + overrideQuery[key] + '\n';
        }

        return carry + `
            ...on ${key} {
              variation {
                ...on default {
                  primary {
                    ...primaryFields
                  }
                  items {
                    ...itemsFields
                  }
                }
              }
            }\n`;
      }, '');

      const graphQuery = `
      {
        page {
          main_category {
            ...on page {
              title
              summary
            }
          }
          sub_category {
            ...on page {
              uid
              title
            }
          }
          image
          summary
          title
          published_override
          slices {
            ${sliceQuery}
          }
        }
      }`;

      // console.log(graphQuery);

      try {
        const response = await $prismic.api.query(
          [
            $prismic.predicate.at('document.type', type),
            $prismic.predicate.at(`my.${type}.uid`, slug)
          ],
          {
            graphQuery: graphQuery.replace(/ /g, ''),
            pageSize: 1,
          }
        );

        if (response && response.results_size > 0) {
          content.value = response.results?.[0];
        }
      } catch (e) {
        Logger.error('PRISMIC: Failed to get data (slice query)', e);
        error.value = e;
      }

      loading.value = false;

      return {
        content,
        error,
        loading
      };
    },

    /**
     * @deprecated
     * @param category
     * @param type
     * @returns {Promise<{error: Ref<UnwrapRef<string>>, loading: Ref<UnwrapRef<boolean>>, content: Ref<UnwrapRef<{}>>}>}
     */
    async queryList(category, type = 'page') {
      const content = ref({});
      const error = ref('');
      const loading = ref(true);

      const graphQuery = `
      {
        page {
          category
          image
          summary
          title
          published_override
          main_category {
            ...on page {
              uid
              title
            }
          }
          sub_category {
            ...on page {
              uid
              title
            }
          }
        }
      }
       `;

      try {
        content.value = await $prismic.api.query(
          [
            $prismic.predicate.at('document.type', type),
            $prismic.predicate.at(`my.${type}.category`, category)
          ],
          {
            graphQuery,
            orderings: '[my.' + type + '.published_override desc, document.first_publication_date desc]',
            pageSize: 60
          }
        );
      } catch (e) {
        Logger.error('PRISMIC: Failed to get data (query list)', e);
        error.value = e;
      }

      loading.value = false;

      return {
        content,
        error,
        loading
      };
    },

    async queryLinkedList({ id, tags = [], categoryType = MAIN_CATEGORY, type = 'page' }) {
      const content = ref({});
      const error = ref('');
      const loading = ref(true);

      const graphQuery = `
      {
        page {
          category
          image
          summary
          title
          published_override
          main_category {
            ...on page {
              uid
              title
            }
          }
          sub_category {
            ...on page {
              uid
              title
            }
          }
        }
      }
       `;

      try {
        content.value = await $prismic.api.query(
          [
            $prismic.predicate.at('document.type', type),
            $prismic.predicate.at(`my.${type}.${categoryType}`, id),
            ...(tags?.length > 0 ? [$prismic.predicate.at('document.tags', tags)] : []),
          ],
          {
            graphQuery,
            orderings: '[document.first_publication_date desc, my.' + type + '.published_override desc]',
            pageSize: 60
          }
        );
      } catch (e) {
        Logger.error('PRISMIC: Failed to get data (linked list)', e);
        error.value = e;
      }

      loading.value = false;

      return {
        content,
        error,
        loading
      };
    }
  };
}
