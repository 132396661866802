<template>
  <section class="section clamped">
    <PrismicRichText :field="slice.primary.title" class="heading" />

    <div class="media-items">
      <div
        v-for="(item, i) in slice.items"
        :key="`slice-item-${i}`"
        class="media-item"
        :style="{'--border-color': `var(--color-${item.borderColor.toLowerCase()})`}"
      >
        <PrismicLink v-if="item.cta && item.cta.url" :field="item.cta" class="outer-border">
          <PrismicImage :field="item.image" class="image" height="275" width="275" />
        </PrismicLink>
        <div v-else class="outer-border">
          <PrismicImage :field="item.image" class="image" />
        </div>
        <div v-if="item.title" class="title">
          {{ item.title }}
        </div>
        <div v-if="item.description" class="details">
          {{ item.description }}
        </div>
        <div v-if="item.ctaText" class="cta">
          <PrismicLink :field="item.cta" class="link">
            <span>{{ item.ctaText }}</span>
          </PrismicLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';

export default {
  name: 'RoundedMediaBlock',
  props: getSliceComponentProps(['slice']),
};
</script>

<style scoped lang="scss">
.section {
  margin-block: 2em;
}

.heading {
  margin-bottom: var(--spacer-sm);
  text-align: center;
}

.media-items {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: min(5vmin, 50px)
}

.media-item {
  --border-size: 32px;
  --max-width: min(340px, 40vw);

  width: var(--max-width);
  flex: 0 0 var(--max-width);
  text-align: center;
  display: block;

  @include for-mobile() {
    --border-size: 20px;
    --max-width: min(250px, 45vw);
  }
}

.outer-border {
  aspect-ratio: 1/1;
  color: $black;
  position: relative;
  display: block;
  margin: var(--border-size);

  &::after {
    content: '';
    background-color: var(--border-color, $green);
    width: calc(100% + 2px + (var(--border-size) * 2));
    height: calc(100% + 2px + (var(--border-size) * 2));
    border-radius: 50%;
    position: absolute;
    top: calc((var(--border-size) + 2px) * -1); // offset with outline on image
    right: calc((var(--border-size) + 2px) * -1); // offset with outline on image
    border: 2px solid var(--black);
    z-index: 0;
    display: block;
  }
}

.image {
  border-radius: 50%;
  border: 2px solid var(--black);
  aspect-ratio: 1 / 1;
  object-fit: cover;
  position: relative;
  z-index: 2;
  padding: 2rem;
  display: block;
  width: calc(100%);
  height: calc(100%);
  background-color: #fff;
  transition: transform .2s;
}

.image:hover {
  transform: scale(1.1);
}

.title {
  font-weight: $font-weight-bold;
  font-size: $font-size-extra-large;
  font-family: $font-primary;
  line-height: 1.4;
  padding: var(--spacer-xs) 0;
}

.details {
  font-size: $font-size;
  padding: var(--spacer-xs) 0;
}

.cta {
  padding: 1.5em 0 1em;
  line-height: 1.5;
}

.cta .link {
  color: $link-color;
  text-decoration: underline;
  text-underline-offset: var(--spacer-2xs);

  &:hover {
    color: $link-color-hover;
  }
}

@include for-desktop() {
  .title {
    font-size: $font-size-up-2;
  }
}
</style>
