<template>
  <section :id="slice.primary.reference" class="section">
    <form class="signup-form">
      <PrismicRichText :field="slice.primary.title" class="title" />
      <fieldset class="info">
        <input
          v-model="email"
          type="email"
          name="email"
          placeholder="Your email address:"
          class="info-input"
        >
        <button type="submit" name="submit" class="action primary" :disabled="sending" @click="onSubmit">
          {{ slice.primary.buttonText }}
          <div v-if="sending" class="loader">
            <span class="circle" />
            <span class="circle" />
            <span class="circle" />
          </div>
          <ArrowRightIcon v-else width="30" height="15" class="arrow-icon" />
        </button>
      </fieldset>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="success-wrapper"
        :style="{ 'display': successMessage ? 'block' : 'none'}"
        v-html="successMessage"
      />
      <!--eslint-enable-->
      <SfNotification
        :message="notificationMessage"
        :type="notificationType"
        :visible="notificationMessage.length > 0"
        class="notification"
        @click:close="notificationMessage = ''"
      />
      <PrismicRichText :field="slice.primary.textForDownloadLink" class="download-details" />
    </form>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import { SfNotification } from '@storefront-ui/vue';
import ArrowRightIcon from '~/assets/icons/arrow-right.svg?inline';
import { Logger } from '@/helpers/logger/index.ts';
import { baseUrl } from '~/modules/test/service/api';

export default {
  name: 'SubscribeDownload',
  components: {
    SfNotification,
    ArrowRightIcon
  },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data () {
    return {
      email: null,
      notificationMessage: '',
      notificationType: 'secondary',
      sending: false,
      successMessage: null
    };
  },
  async mounted () {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      Logger.error(e);
    }
  },
  beforeDestroy () {
    this.$recaptcha.destroy();
  },
  methods: {
    async onSubmit (e) {
      e.preventDefault();
      if (this.sending) { return; }
      this.notificationMessage = '';
      this.successMessage = '';
      this.notificationType = 'secondary';

      try {
        if (!this.email) {
          this.notificationMessage = 'Please provide your email address.';
          this.notificationType = 'danger';
          return;
        } else if (!this.validEmail(this.email)) {
          this.notificationMessage = 'Please enter valid email address.';
          this.notificationType = 'danger';
          return;
        }
        this.sending = true;

        const token = await this.$recaptcha.execute('newsletter');
        const fetchUrl = baseUrl() + 'email/subscribe/' + this.getEmailId();

        await fetch(fetchUrl, {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            X_REQUESTED_WITH: 'XMLHttpRequest'
          },
          body: JSON.stringify({
            email: this.email,
            recaptcha: token,
            message: this.message,
            currentPage: {
              route: this.$route.fullPath,
              name: this.$route.name,
            }
          })
        })
          .then(response => response.json())
          .then(this.handleResponse.bind(this));
      } catch (error) {
        Logger.error('Login error:', error);
        this.notificationMessage = error.message;
        this.notificationType = 'danger';
      }

      this.sending = false;
    },

    validEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    getEmailId() {
      const emailIds = {
        'Frontend Developer': 1,
        'Associate Developer': 2,
        'Solution Specialist': 3,
        'Professional Developer': 4,
        'Javascript Developer': 5,
        'Professional Developer Plus': 6,
        'Cloud Developer': 7,
        'Actionable Insights': 8,
        'December 2020 Empowering Merchants': 9,
        'Mastering Xdebug': 10,
        'Art of Debugging': 11,
        '2021 Ecommerce Developer Report': 12,
        '2022 Ecommerce Developer Report': 13,
        Merchandising: 14,
        'eCommerce Almanac': 15,
        'eCom Buzz': 16
      };
      return emailIds[this.slice.primary.emailId] || '';
    },

    handleResponse(response) {
      if (typeof response !== 'object' || response.trace) { // error
        this.notificationMessage = 'An error occurred: ' + response.message;
        this.notificationType = 'danger';
      }

      if (response?.success_text?.length > 0) {
        this.successMessage = response.success_text;
      } else if (response?.study_guide?.url.length > 0) {
        this.successMessage = `Woot!! You can now <a href="${response.study_guide_url}" target="_blank">download the ${response.group_name} study guide</a>.`;
      } else {
        this.successMessage = 'You are now in line to take that next step. Congratulations and thank you!';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.section {
  border-top: $border-width-2x solid $color-secondary;
  border-bottom: $border-width-2x solid $color-secondary;
  max-width: $tablet-min;
  margin: var(--spacer-base);
  padding: var(--spacer-sm) 0;

  @include for-desktop {
    margin: var(--spacer-base) auto;
  }
}

.loader {
  width: 48px;
  display: inline-flex;
  position: relative;

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $color-orange;
    animation: move 500ms linear 0ms infinite;
    margin-right: 15px;

    &:first-child{
      position: absolute;
      top:0;
      left:0;
      animation: grow 500ms linear 0ms infinite;
    }

    &:last-child{
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0;
      animation: grow 500ms linear 0s infinite reverse;
    }
  }
}

@keyframes grow {
  from {transform: scale(0,0); opacity: 0;}
  to {transform: scale(1,1); opacity: 1;}
}

@keyframes move {
  from {transform: translateX(0px)}
  to {transform: translateX(45px)}
}

.info {
  border: none;
  display: flex;
  gap: 1em;
  padding: 0;

  @include for-mobile {
    flex-direction: column;
  }
}

.info-input {
  border: var(--primary-border);
  border-radius: var(--small-radius);
  padding: var(--spacer-sm);
  flex-grow: 1;
  font-size: $font-size-medium;
}

.download-details {
  margin-top: var(--spacer-xl);
}

.action.primary {
  border: var(--primary-border);
  border-radius: var(--big-radius);
  letter-spacing: 1px;
  padding: var(--spacer-sm);
  background: transparent;
  flex-grow: 1;
  color: $primary__color;
  text-transform: uppercase;
  position: relative;
  transition: background-color 100ms ease-out, box-shadow 100ms ease-in;

  @include bp($medium, $min) {
    font-size: $font-size-medium;
  }

  &:hover {
    text-decoration: none;
    background-color: $primary__color;
    color: #fff;
    cursor: pointer;
  }

  &:disabled {
    cursor: auto;
  }
}

.arrow-icon {
  margin-left: var(--spacer-sm);
}

.success-wrapper {
  background-color: $very-light-gray;
  border-radius: $border-width;
  padding: var(--spacer-sm);
  margin: var(--spacer-sm) 0;
}

.notification {
  margin: var(--spacer-base) 0;
}
</style>
