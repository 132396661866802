/**
 * Add ALL new components here.
 */

const map = {
  audio_embed: 'AudioEmbed',
  block_quote: 'BlockQuote',
  call_to_action_button: 'CallToActionButton',
  card_list: 'CardList',
  faq_list: 'FaqList',
  heading: 'Heading',
  multi_column: 'MultiColumn',
  contact_form: 'ContactForm',
  hubspot_form: 'HubspotForm',
  content_list: 'ContentList',
  dynamic_table: 'DynamicTable',
  tiktok_carousel: 'TiktokCarousel',
  hero_banner: 'HeroBanner',
  icon_text_grid: 'IconTextGrid',
  iframe_renderer: 'IframeRenderer',
  image_grid: 'ImageGrid',
  include_block: 'IncludeBlock',
  reviews_slider: 'MediaSlider',
  media_text_block: 'MediaTextBlock',
  product_block: 'ProductBlock',
  profile_card: 'ProfileCard',
  rounded_media_block: 'RoundedMediaBlock',
  statistic_callout: 'StatisticCallout',
  sticky_menu: 'StickyMenu',
  subscribe_download: 'SubscribeDownload',
  text_content: 'TextContent',
  video_block: 'VideoBlock',
};

export default map;
