<template>
  <ValidationProvider v-slot="v">
    <div class="label-input"
         :class="{ active: labelOffset }"
    >
      <slot name="label">
        <label :for="id">{{ label }}</label>
      </slot>
      <input v-if="type !== 'textarea'"
             class="field"
             ref="input"
             :type="type"
             :id="id"
             :required="required"
             :autofocus="autofocus"
             :autocomplete="autocomplete"
             :value="value"
             :name="label"
             placeholder=""
             @input="updateValue($event.target.value)"
             @keyup.enter="enter($event.target.value)"
             @focus="swapLabel"
             @change="updateEvent($event.target.value)"
             @blur="blurLabel($event.target.value)"
      />
      <textarea v-else
                class="field"
                ref="textarea"
                :id="id"
                :required="required"
                :autofocus="autofocus"
                :name="label"
                rows="1"
                @input="updateValue($event.target.value)"
                @keyup.enter="enter($event.target.value)"
                @focus="swapLabel"
                @change="updateEvent($event.target.value)"
                @blur="blurLabel($event.target.value)"
      >{{ value }}</textarea>
      <slot name="after-input"/>
      <slot name="after-help"/>

      <div class="field-error-list"
           v-if="isInvalid"
      >
        <div v-for="error in errors.all()"
             :key="error.id"
             class="field-error"
        >{{ error }}
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'LabelInput',
  props: {
    label: {
      required: true,
      validator: String,
    },
    id: {
      required: true,
      type: String
    },
    value: {
      required: false,
      type: String,
      default: ''
    },
    type: {
      default: 'text',
      required: false,
      type: String
    },
    required: {
      default: true,
      required: false,
      type: Boolean
    },
    autofocus: {
      default: false,
      required: false,
      type: Boolean
    },
    autocomplete: {
      required: false,
      type: String,
      default: ''
    },
    validate: {
      required: false,
      type: String,
      default: ''
    },
  },
  components: {
    ValidationProvider
  },
  data () {
    return {
      labelIsOffset: false,
    }
  },
  computed: {
    labelOffset () {
      if (this.value) {
        return true
      }

      return this.labelIsOffset
    },
    isInvalid () {
      return this.errors && this.errors.items.length > 0
    },
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value, this.id)

      if (this.$refs.textarea) {
        let element = this.$refs.textarea
        element.style.height = element.scrollHeight + 'px'
      }
    },

    updateEvent (value) {
      if (value !== String(this.value)) {
        this.$emit('update', value, this.id)
      }
    },

    enter (value) {
      this.$emit('update', value, this.id)
      this.$emit('enter', value)
    },

    swapLabel () {
      this.labelIsOffset = true
    },

    blurLabel (value) {
      this.updateEvent(value)
      this.$emit('blur', value, this.id, {})

      if (!value) {
        this.labelIsOffset = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
div {
  position: relative;
}

.field {
  border: none;
  border-bottom: solid 2px $dark;
  width: 100%;
  max-width: var(--field-max-width, 450px);
  font-size: 1rem;
  padding: 1.6em var(--input-pad-right, 1em) 0.8em 1em;

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

input {
  font-family: monospace;
}

textarea {
  resize: vertical;
  max-height: 220px;
  min-height: 52px; // magic number
  font-family: monospace;
}

label {
  position: absolute;
  top: 1.4em;
  left: 1em;
  text-transform: none;
  transform-origin: top left;
  transition: transform 200ms ease-out, opacity 200ms ease-out;
  background-color: $white;
}

.active label {
  transform: translateY(-1em) translateX(-0.1em) scale(0.75);
  opacity: 0.7;
}

.field-error-list {
  color: $red;
  font-weight: bold;
  font-size: 0.7rem;
  margin-bottom: -0.7em;
}

.label-input {
  padding-bottom: var(--bottom-gap, 1.3rem);
}
</style>
