<template>
  <section :id="slice.primary.reference" class="video-container">
    <ShowOnView :force-show="!!slice.primary.disable_lazy_load">
      <PrismicEmbed :field="slice.primary.video" class="video" />
    </ShowOnView>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import ShowOnView from '~/components/General/ShowOnView.vue';

export default {
  name: 'VideoBlock',
  components: { ShowOnView },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
};
</script>

<style scoped lang="scss">
.video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  max-width: $desktop-min;
  text-align: center;
  margin: var(--spacer-sm) auto;
  border-radius: 1.5rem;
  background-color: $very-light-gray;

  @include for-desktop($desktop-l-min) {
    margin: var(--spacer-base) auto;
  }
}

.video ::v-deep {
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
