<template>
  <div :id="slice.primary.reference" class="clamped wrapper">
    <SfNotification
      :message="notificationMessage"
      :type="notificationType"
      :visible="notificationMessage.length > 0"
      class="notification"
      @click:close="notificationMessage = ''"
    />

    <form class="form">
      <PrismicRichText :field="slice.primary.title" class="title" />
      <PrismicRichText :field="slice.primary.intro" />

      <div class="fields">
        <fieldset class="info">
          <LabelInput
            id="name"
            v-model="name"
            label="Full Name"
            class="name info-field"
          />

          <LabelInput
            id="email"
            v-model="email"
            type="email"
            label="Email"
            class="email info-field"
          />
        </fieldset>

        <LabelInput
          id="message"
          v-model="message"
          type="textarea"
          label="Message"
          class="message"
        />

        <button
          type="button"
          class="btn-primary"
          name="Submit Form"
          :disabled="sending"
          @click="onSubmit"
        >
          <template v-if="!sending">
            Send inquiry
          </template>
          <template v-else>
            Sending...
          </template>
        </button>
      </div>

      <img src="./assets/lightning.png" alt="Lightning Image (Expect a fast response)" height="441" width="250" class="feature-image">
    </form>
  </div>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import { SfNotification } from '@storefront-ui/vue';
import LabelInput from '@/modules/test/components/App/Form/LabelInput';
import { Logger } from '@/helpers/logger';

export default {
  name: 'ContactForm',
  components: {
    SfNotification,
    LabelInput
  },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data () {
    return {
      email: '',
      name: '',
      message: '',
      notificationMessage: '',
      notificationType: 'secondary',
      sending: false,
    };
  },
  async mounted () {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      Logger.error(e);
    }
  },
  beforeDestroy () {
    this.$recaptcha.destroy();
  },
  methods: {
    async onSubmit () {
      if (this.sending) { return; }

      this.notificationMessage = '';
      this.notificationType = 'secondary';

      this.$gtm?.push({
        event: 'contact',
      });

      try {
        // eslint-disable-next-line prefer-const
        let [firstname, ...lastname] = this.name.split(' ');
        lastname = lastname.join(' ');

        if (!this.verify({
          firstname,
          lastname
        })) {
          return;
        }

        this.sending = true;

        const token = await this.$recaptcha.execute('login');
        Logger.debug('ReCaptcha token:', token);

        const result = await fetch('/contact/form/submission', {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            X_REQUESTED_WITH: 'XMLHttpRequest'
          },
          body: JSON.stringify({
            email: this.email,
            firstname,
            lastname,
            recaptcha: token,
            message: this.message,
            currentPage: {
              route: this.$route.fullPath,
              name: this.$route.name,
            }
          })
        })
          .then(response => response.json());

        if (result.success) {
          this.notificationMessage = 'On it! Expect a response from contact@swiftotter.com or a similar address soon.';
          this.notificationType = 'success';
          this.name = this.email = this.message = '';
        }
      } catch (error) {
        Logger.error('Login error:', error);
        this.notificationMessage = error.message + ' Feel free to email us at contact@swiftotter.com.';
        this.notificationType = 'danger';
      }

      this.sending = false;
    },

    verify ({
      firstname,
      lastname
    }) {
      this.notificationMessage = '';

      if (!firstname || !lastname) {
        this.notificationMessage = 'Please provide your first and last name.';
      }

      if (!this.email && this.email.indexOf('@') > 0) {
        this.notificationMessage = 'Please provide your email address.';
      }

      if (!this.message) {
        this.notificationMessage = 'Please write a message.';
      }

      if (this.notificationMessage) {
        this.notificationType = 'danger';

        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.title::v-deep h3 {
  font-size: clamp(2rem, 5vmin, 3rem);
}

.wrapper {
  @include for-desktop() {
    padding-right: 2em;
  }
}

.notification {
  margin-block: 1em;
  --notification-color: $black;
  --icon-color: $black;
}

.form {
  border: $border-width solid $black;
  position: relative;
  padding: calc(15% + 2rem) calc(5% + 2rem) 2rem 2rem;
  margin-block: 20% 10%;
  --field-max-width: none;
  --max-overflow: 1.9rem;

  @include for-desktop {
    padding-block-start: 2rem;
    padding-right: calc(15% + 4rem); // FF did not like the logical property
    margin-top: 1rem;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: $border-width * -1;
    right: $border-width * -1;
    bottom: $border-width * -1;
    border: $border-width solid $black;
    background-color: $yellow-green;
    width: 5%;
    z-index: 0;
  }
}

fieldset {
  border: none;
  padding: 0;
}

.info {
  display: flex;
  gap: 1em;

  @include for-mobile {
    flex-direction: column;
  }
}

.info-field {
  flex-basis: 50%;
  position: relative;
  z-index: 20;
}

.message {
  width: 100%;
  position: relative;
  z-index: 20;
}

.feature-image {
  position: absolute;
  z-index: 10;

  @include for-mobile {
    top: 0;
    right: 10%;
    max-width: #{calc(15% + var(--max-overflow))};
    transform: translateY(-40%);
  }

  @include for-desktop {
    top: 10%;
    right: #{calc(var(--max-overflow) * -1)};
    max-width: #{calc(15% + var(--max-overflow))};
  }
}
</style>
