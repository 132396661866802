<template>
  <div ref="observed" class="wrap">
    <span class="loading-element">Loading...</span>
    <slot v-if="isVisible || forceShow" />
  </div>
</template>

<script>
export default {
  name: 'ShowOnView',
  props: {
    forceShow: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  mounted () {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.isVisible = true;
      }
    });

    setTimeout(() => {
      if (this.$refs.observed || this.forceShow) {
        observer.observe(this.$refs.observed);
      } else {
        this.isVisible = true; // fallback
      }
    });
  }
};
</script>

<style scoped>
.wrap {
  height: 100%;
  display: grid;
}
.loading-element {
  margin: auto;
}
</style>
