<template>
  <div
    :style="`--bg-color: var(--color-${renderBgColor})`"
    :class="'hero'
      + (imageOnRight ? ' has-image-on-right' : ' has-image-on-left')
      + (hasColor ? ' has-color' : ' has-no-color')"
  >
    <div class="image">
      <ResizedImage :field="image" width="480" height="480" :loading="lazy ? 'lazy' : 'eager'" />
    </div>
    <div class="inside">
      <div class="contents">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import ResizedImage from '~/components/General/ResizedImage';

export default {
  name: 'SideBySideHeroImage',
  components: { ResizedImage },
  props: {
    image: {
      type: Object,
      default: () => ({})
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    imageOnRight: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasColor () {
      return this.backgroundColor && this.backgroundColor !== 'transparent';
    },
    renderBgColor () {
      return this.backgroundColor?.toLowerCase() || 'transparent';
    }
  }
};
</script>

<style scoped lang="scss">
.hero {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2em;
  max-width: $desktop-xl-min;
  margin: var(--spacer-2xl) auto;
  min-height: min(calc(450px + 3vmin), 60vh);
  border-radius: $border-radius-hero;
  background-color: var(--bg-color);
  padding: 1em;

  @include for-mobile {
    margin-bottom: 0;
    flex-direction: column;
  }
}

.has-image-on-right {
  flex-direction: row-reverse;

  @include for-mobile {
    flex-direction: column;
  }
}

.has-color {
  border: 2px solid $dark;
  color: $white;
}

.image {
  z-index: 0;
  max-width: clamp(400px, 50%, 80vw);
  height: 100%;
  position: relative;

  @include for-desktop {
    flex: 1 0 480px;
  }

  > img {
    border-radius: $border-radius-2xl;
  }
}

img {
  margin-top: calc(-20% - 1em);
  object-fit: contain;

  @include for-desktop {
    margin-bottom: calc(-20% - 1em);
  }
}

// Images over transparent backgrounds are not stretched
.has-no-color img {
  margin: 0;
}

.inside {
  display: flex;
  justify-content: right;
}

.contents {
  position: relative;
  z-index: 10;
  text-align: right;
  max-width: 500px;

  @include for-mobile {
    text-align: center;
    max-width: clamp(500px, 80%, 80vw);
    margin: auto;
  }
}
</style>
