function calculated(props, requestedWidth, requestedHeight) {
  let src = `${props?.field?.url}`;
  src = src.replace('?compress,format&', '&');
  const noSize = src.replace(/&\\?[wh]=\d{1,4}/gm, '');

  const steps = [1, 1.5, 2, 3];
  const srcsetDetails = {};
  steps.forEach(i => {
    if (props?.field?.dimensions?.width && requestedWidth * i <= props?.field?.dimensions?.width &&
      props?.field?.dimensions?.height && requestedHeight * i <= props?.field?.dimensions?.height) {
      srcsetDetails[i] = `${noSize}&${new URLSearchParams({w: Math.round(requestedWidth * i), h: Math.round(requestedHeight * i)}).toString()}`;
    }
  });

  if (props?.field?.dimensions?.width && props?.field?.dimensions?.height) {
    const maxRatio =
      Math.min(
        Math.round((props?.field?.dimensions?.width / requestedWidth) * 10) / 10,
        Math.round((props?.field?.dimensions?.height / requestedHeight) * 10) / 10
      );
    srcsetDetails[maxRatio] = `${noSize}&${new URLSearchParams({w: Math.round(requestedWidth * maxRatio), h: Math.round(requestedHeight * maxRatio)}).toString()}`;
  }

  let srcset = '';
  for (const [size, url] of Object.entries(srcsetDetails)) {
    srcset = `${srcset}${url} ${size}x,`;
  }

  return {
    srcset,
    src: noSize
  };
}

function predefined(props, requestedWidth, requestedHeight) {
  let src = `${props?.field?.url}`;
  src = src.replace('?compress,format&', '&');
  src = src.replace(/&\\?[wh]=\d{1,4}/gm, '');
  src = `${src}&${new URLSearchParams({w: requestedWidth, h: requestedHeight}).toString()}`;

  return {
    srcset: src
  };
}

export default {
  name: 'ResizedImage',
  functional: true,
  props: {
    field: {
      type: Object,
      required: true
    },
    alt: {
      type: [String, null],
      required: false
    },
    width: {
      type: [Number, String],
      required: true
    },
    height: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      default: 'img',
      required: false
    },
    htmlWidth: {
      type: [Number, String, null],
      required: false,
      default: null
    },
    htmlHeight: {
      type: [Number, String, null],
      required: false,
      default: null
    },
  },
  render (createElement, { props, data }) {
    const requestedWidth = props.htmlWidth || props.width;
    const requestedHeight = props.htmlHeight || props.height;
    Object.assign(data, {
      attrs: {
        ...data.attrs,
        ...(props.type === 'source'
          ? predefined(props, requestedWidth, requestedHeight)
          : calculated(props, requestedWidth, requestedHeight)
        )
      }
    });

    return createElement(
      props.type || 'img',
      Object.assign(data, {
        attrs: {
          ...data.attrs,
          alt: props.alt || props.field?.alt || '',
          width: requestedWidth,
          height: requestedHeight
        }
      })
    );
  }
};
