




















































































































import {
  SfSelect,
  SfHeading,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent, onMounted, ref, ssrRef, useFetch, useRoute,
} from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import CategoryPagination from '~/modules/catalog/category/components/pagination/CategoryPagination.vue';
import {
  useFacet,
  useUiHelpers,
  useUiState,
} from '~/composables';

import { useAddToCart } from '~/helpers/cart/addToCart';
import { useUrlResolver } from '~/composables/useUrlResolver';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { usePrice } from '~/modules/catalog/pricing/usePrice';
// import { useCategoryContent } from '~/modules/catalog/category/components/cms/useCategoryContent';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';

import CategoryNavbar from '~/modules/catalog/category/components/navbar/CategoryNavbar.vue';
import CategoryBreadcrumbs from '~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue';
import { isCategoryTreeRoute } from '~/modules/GraphQL/CategoryTreeRouteTypeguard';

import type { ProductInterface, CategoryTree } from '~/modules/GraphQL/types';
import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { Pagination } from '~/composables/types';
import type { Product } from '~/modules/catalog/product/types';
import useCmsContent from '~/prismic/composables/useCmsContent';
import { Logger } from '~/helpers/logger';
import CategoryFeature from '~/modules/catalog/category/components/cms/CategoryFeature.vue';

export default defineComponent({
  name: 'CategoryPage',
  components: {
    CategoryFeature,
    CategoryPagination,
    CategoryEmptyResults: () => import('~/modules/catalog/category/components/CategoryEmptyResults.vue'),
    // CategoryFilters: () => import('~/modules/catalog/category/components/filters/CategoryFilters.vue'),
    CategoryProductGrid: () => import('~/modules/catalog/category/components/views/CategoryProductGrid.vue'),
    CategoryProductList: () => import('~/modules/catalog/category/components/views/CategoryProductList.vue'),
    CategoryNavbar,
    CategoryBreadcrumbs,
    SfSelect,
    SfHeading,
    SkeletonLoader,
  },
  transition: 'fade',
  setup(props, { root }) {
    // const { getContentData } = useCategoryContent();
    const { addTags } = useCache();
    const uiHelpers = useUiHelpers();
    const cmsContent = ref({});
    const isShowCms = ref(true);
    const isShowProducts = ref(false);
    const products = ssrRef<ProductInterface[]>([]);
    const sortBy = ref<SortingModel>({ selected: '', options: [] });
    const pagination = ref<Pagination>({});
    // @ts-ignore
    const { search: cmsSearch } = useCmsContent(root?.$prismic);
    const route = useRoute();

    const productContainerElement = ref<HTMLElement | null>(null);

    const { search: resolveUrl } = useUrlResolver();
    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
    } = useUiState();
    const {
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();
    const { result, search } = useFacet();
    const { addItemToCart } = useAddToCart();

    const addItemToWishlist = async (product: Product) => {
      await (isInWishlist({ product })
        ? removeItemFromWishlist({ product })
        : addItemToWishlistBase({ product }));
    };

    const { activeCategory, loadCategoryTree } = useTraverseCategory();
    const activeCategoryName = computed(() => activeCategory.value?.name ?? '');
    const activeCategoryUrlPath = computed(() => (activeCategory.value?.url_path || route.value.path.replace('/shop/', '') || ''));
    const routeData = ref<CategoryTree | null>(null);

    const { fetch } = useFetch(async () => {
      if (!activeCategory.value) {
        loadCategoryTree();
      }
      const resolvedUrl = await resolveUrl();

      if (isCategoryTreeRoute(resolvedUrl)) { routeData.value = resolvedUrl; }

      const categoryUid = routeData.value?.uid;

      Logger.info('Loading', activeCategoryUrlPath.value);

      const [cms] = await Promise.all([
        // getContentData(routeData.value?.uid),
        cmsSearch({
          type: 'category',
          key: activeCategoryUrlPath.value,
          field: 'uid',
        }),
        search({ ...uiHelpers.getFacetsFromURL(), category_uid: categoryUid }),
      ]);

      // @ts-ignore
      cmsContent.value = cms?.content?.value ?? {};
      // isShowCms.value = content.isShowCms; // TODO: reenable after getting cache-clearing working
      // @ts-ignore
      isShowProducts.value = cms?.content?.value?.data?.show_products !== undefined ? cms?.content?.value?.data?.show_products : true;

      // @ts-ignore
      if (cms.error?.value) {
        // @ts-ignore
        Logger.error('Failed to load Prismic Category Feature', cms.error?.value);
      }

      products.value = facetGetters.getProducts(result.value) ?? [];
      sortBy.value = facetGetters.getSortOptions(result.value);
      pagination.value = facetGetters.getPagination(result.value);

      const tags = [{ prefix: CacheTagPrefix.View, value: 'category' }];
      const productTags = products.value?.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product.uid,
      }));

      addTags([...tags, ...productTags]);
    });

    const isPriceLoaded = ref(false);
    onMounted(async () => {
      const { getPricesBySku } = usePrice();
      if (products.value.length > 0) {
        const skus = products.value.map((item) => item.sku);
        const priceData = await getPricesBySku(skus, pagination.value.itemsPerPage);
        products.value = products.value.map((product) => ({
          ...product,
          price_range: priceData.items.find((item) => item.sku === product.sku)?.price_range,
        }));
      }
      isPriceLoaded.value = true;
    });

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetch();
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(0);
    };

    const onReloadProducts = () => {
      goToPage(0);
      productContainerElement.value.scrollIntoView();
    };

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      sortBy,
      isShowCms,
      isShowProducts,
      cmsContent,
      activeCategoryName,
      routeData,
      doChangeItemsPerPage,
      productContainerElement,
      onReloadProducts,
      goToPage,
    };
  },
  // @ts-ignore
  head() {
    return {
      title: this.activeCategoryName || 'SwiftOtter',
      // TODO: pull short description in here somehow
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description',
      //     content: getShortDescription(this.product)
      //   }
      // ]
    };
  }
});
