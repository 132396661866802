<template>
  <div
    class="hero"
    :style="!roundTopCorners ? '--top-border-radius: 0' : ''"
  >
    <picture v-if="image.small.url || image.url" class="image">
      <ResizedImage
        v-if="image.small.url"
        :field="image"
        width="600"
        height="470"
        :srcset="image.small.url"
        type="source"
        media="(max-width: 768px)"
      />

      <ResizedImage
        v-if="image.url"
        :field="image"
        width="1440"
        height="490"
        :srcset="image.url"
        type="source"
        media="(min-width: 769px)"
      />

      <ResizedImage
        :field="image"
        :loading="lazy ? 'lazy' : 'eager'"
        width="1440"
        height="490"
      />
    </picture>
    <div class="overlay" />
    <div class="inside clamped">
      <div class="contents">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import ResizedImage from '~/components/General/ResizedImage';

export default {
  name: 'DefaultHeroImage',
  components: { ResizedImage },
  props: {
    image: {
      type: Object,
      default: () => ({})
    },
    roundTopCorners: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: $desktop-xl-min;
  margin: 0 auto var(--spacer-base);
  min-height: min(calc(450px + 3vmin), 60vh);
  overflow: hidden;
  border-radius: var(--top-border-radius, $border-radius-hero) var(--top-border-radius, $border-radius-hero) $border-radius-hero $border-radius-hero;
}

.overlay,
.image,
img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}

.overlay {
  background-color: rgba($navy, 0.5);
}

.contents {
  position: relative;
  z-index: 10;
  text-align: left;
  color: $white;
  max-width: clamp(400px, 50%, 80vw);
}

.contents ::v-deep a {
  color: $white;
  text-decoration: underline;
}
</style>
