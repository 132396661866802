function headers() {
  return new Headers({
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'X_REQUESTED_WITH': 'XMLHttpRequest'
  });
}

export function baseUrl() {
  const prefix = process.client
    ? window.location.origin
    : '';

  return prefix + `${process.env.TEST_API_URL}`;
}

function beforeResponse(response) {
  const notifier = new CustomEvent('fetchEnd', {detail: response})
  document.body.dispatchEvent(notifier);

  if (response && !response.ok && response.status === 401) {
    response.handledError = true
  }

  return response;
}

async function processJson(response) {
  if (response.handledError) {
    return {};
  }

  const json = await response.json();

  if ((json.message || json.messages) && json.success !== true) {
    throw new Error(json.message || json.messages);
  }

  return json;
}

class ApiConnection {
  constructor() {
    this.options = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X_REQUESTED_WITH': 'XMLHttpRequest'
      }
    };

    this.baseUrl = baseUrl();
  }

  json(url = '', opts = {}) {
    opts = Object.assign({
      headers: headers(),
      errors: false,
      cache: 'no-store',
      credentials: 'include'
    }, opts);

    const fetchUrl = baseUrl() + url;

    return fetch(fetchUrl, opts)
      .then(beforeResponse.bind(this))
      .then(processJson)
      .catch(error => {
        if (opts.errors) {
          throw error;
        } else if (error && typeof error === 'object') {
          return Object.assign(error, {error: true})
        } else {
          return {error: true};
        }
      })
  }

  raw(url = '', opts = {}) {
    opts = Object.assign({
      headers: headers(),
      cache: 'no-store',
      credentials: 'include',
    }, opts);

    return fetch(baseUrl() + url, opts)
      .then(beforeResponse.bind(this))
  }

  config() {
    return this.json('app');
  }

  token() {
    let options = this.options;
    options.method = 'POST';

    return this.json('test/token', options);
  }


  getCustomerTokenByShareToken(shareToken) {
    let options = this.options;
    options.method = 'POST';
    options.body = JSON.stringify({
      shareToken: shareToken
    })

    return this.json('shared/test/token', options);
  }

  /** Run store.dispatch('auth/load'); before this **/
  async test(id, auth) {
    const bodyClasses = document.body.className;

    return this.json(`test/${auth}/${id}/?body_classes=${bodyClasses}`)
  }

  answer({ tokenId, examId, questionId, answerIds }) {
    return this.json(`test/answer/${tokenId}/${examId}`, {
      method: 'POST',
      body: JSON.stringify({
        question_id: questionId,
        answer_ids: answerIds
      }),
      errors: true
    });
  }

  survey(tokenId, testId, answers) {
    let options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify({answers})
    }

    return this.json(`test/survey/${tokenId}/${testId}`, options)
  }

  finish(tokenId, testId) {
    let options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify({
        bodyClasses: document.body.className
      })
    }

    return this.json(`test/finish/${tokenId}/${testId}`, options);
  }

  stats(tokenId, testId, details) {
    let options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify({details})
    }

    return this.json(`test/stats/${tokenId}/${testId}`, options)
  }

  needsToLogin(email) {
    return this.raw(`needsPassword`, {
      method: 'POST',
      body: JSON.stringify({email})
    })
      .then(response => response.json())
      .then(response => {
        return (response.success)
          ? response.result
          : false;
      });
  }

  submitCheckout(checkoutObj) {
    const options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify(checkoutObj)
    };

    if (checkoutObj.recaptchaToken) {
      options.headers['X-ReCaptcha'] = checkoutObj.recaptchaToken;
    }

    return this.json(`download/place-order`, options);
  }

  addToCart(checkoutObj) {
      const options = {
          ...this.options,
          method: 'POST',
          body: JSON.stringify(checkoutObj)
      };

      return this.json(`add-to-cart`, options);
  }

  getToken() {
    const options = {
      ...this.options,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        credentials: 'include'
      },
      method: 'GET'
    };

    return this.json(`download/token`, options)
  }

  purchaseExam(paymentData) {
    if (!paymentData.order.userToken || !paymentData.order.examId) {
      throw new Error('UserToken or ExamId not specified. Unable to complete as requested.')
    }

    const userToken = paymentData.order.userToken,
      examId = paymentData.order.examId;

    const options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify(paymentData)
    };

    return this.json(`test/purchase/${userToken}/${examId}`, options);
  }

  refreshPrice(details) {
    const options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify(details)
    };

    return this.json(`test/purchase/price/${details.userToken}/${details.examId}`, options);
  }

  coupon({product_identifier, discount}) {
    const options = {
      ...this.options,
      method: 'GET',
    };

    console.log(product_identifier, discount);

    return this.json(`download/current-price/${encodeURIComponent(product_identifier)}?discount=${discount}`, options);
  }

  examCoupon(coupon) {
    const options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify(coupon)
    };

    return this.json(`voucher/apply`, options);
  }

  email(localTokenId, testId, email, name) {
    const options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify({
        email,
        name
      })
    }

    return this.json(`test/email/${localTokenId}/${testId}`, options);
  }

  register({
             email,
             password,
             firstname,
             lastname,
             recaptchaToken
           }) {
    const options = {
      ...this.options,
      method: 'POST',
      body: JSON.stringify({
        customer: {
          email,
          firstname,
          lastname
        },
        password
      })
    }

      if (recaptchaToken) {
          options.headers['X-ReCaptcha'] = recaptchaToken;
      }

    return this.json(`customers`, options);
  }

  login(localTokenId, username, password, recaptchaToken) {
    const options = {
      ...this.options,
      method: 'POST',
      headers: {
        'Content-Type': "application/x-www-form-urlencoded",
        'X-Requested-With': 'XMLHttpRequest',
        credentials: 'include'
      },
      body: `token=${encodeURIComponent(localTokenId)}&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
    }

    if (recaptcha) {
        options.headers['X-ReCaptcha'] = recaptchaToken;
    }

    return fetch(`${baseUrl(true)}test/login/`, options)
      .then(response => response.json());
  }

  app({hash}) {
    return this.json('app?hash='+encodeURIComponent(hash))
  }

  purchaseInit(sku, country) {
    let options = {
      ...this.options,
      method: 'GET'
    }

    return this.json(`download/init/${sku}?country_code=${country}`, options);
  }

  testsByCustomer() {
    return this.json(`test/listByCustomer`, {
      method: 'POST'
    });
  }

  b2bInit({email, couponCode, country}) {
    let options = {
        ...this.options,
        method: 'POST',
        body: JSON.stringify({
            email,
            country,
            coupon_code: couponCode
        })
    }

    return this.json(`quick-add`, options);
  }

  developerDetails(clientId) {
    let options = {
      ...this.options,
      method: 'GET'
    }

    return this.json(`developer/${clientId}`, options);
  }

  developerList(params) {
    // include criteria
    const queryString = this.serializeQuery(params);

    let options = {
      ...this.options,
      method: 'GET'
    }

    return this.json(`developers?` + queryString, options);
  }

  certificationList() {
    let options = {
      ...this.options,
      method: 'GET'
    }

    return this.json(`certifications`, options);
  }

  serializeQuery(params, prefix) {
    const query = Object.keys(params).map((key) => {
      const value  = params[key];

      if (params.constructor === Array)
        key = `${prefix}[]`;
      else if (params.constructor === Object)
        key = (prefix ? `${prefix}[${key}]` : key);

      if (typeof value === 'object')
        return this.serializeQuery(value, key);
      else
        return `${key}=${encodeURIComponent(value)}`;
    });

    return [].concat.apply([], query).join('&');
  }
}

const Api = new ApiConnection();

export default Api;
