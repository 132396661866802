import { render, staticRenderFns } from "./CategoryFeature.vue?vue&type=template&id=cc4c201c&scoped=true&"
import script from "./CategoryFeature.vue?vue&type=script&lang=js&"
export * from "./CategoryFeature.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc4c201c",
  null
  
)

export default component.exports