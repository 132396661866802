<template>
  <section
    :id="slice.primary.reference"
    class="clamped media-text"
    :style="styles"
  >
    <PrismicLink v-if="slice.primary.image && slice.primary.image_link && slice.primary.image_link.url" :field="slice.primary.image_link" class="image-wrapper">
      <ResizedImage
        v-if="slice.primary.image"
        :field="slice.primary.image"
        :width="600"
        :height="400"
        :loading="index > 3 ? 'lazy' : 'eager'"
        class="image"
      />
    </PrismicLink>
    <div v-else-if="slice.primary.image" class="image-wrapper">
        <ResizedImage
          v-if="slice.primary.image"
          :srcset="slice.primary.image"
          :field="slice.primary.image"
          :width="600"
          :height="400"
          :loading="index > 3 ? 'lazy' : 'eager'"
          class="image"
        />
    </div>

    <div class="content-wrapper cms">
      <PrismicRichText :field="slice.primary.content" class="text" />

      <div v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
        <PrimaryButton v-if="item.call_to_action && item.call_to_action.url" :link="item.call_to_action" :text="item.action_text" :icon="item.icon" />
      </div>
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import PrimaryButton from '@/components/General/PrimaryButton';
import ResizedImage from '~/components/General/ResizedImage';

export default {
  name: 'MediaTextBlock',
  components: { ResizedImage, PrimaryButton },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    styles () {
      const imgPlacement = this.slice.primary.imagePlacement;

      return {
        '--direction': imgPlacement === 'Right' ? 'row-reverse' : '',
        '--justify': imgPlacement === 'Center' ? 'center' : 'space-between',
        '--border-width': this.slice.primary.imageBorder ? 'var(--border-width-default)' : '0'
      };
    }
  }
};
</script>

<style scoped lang="scss">
.media-text {
  padding-block-start: var(--spacer-base);
  padding-block-end: var(--spacer-base);
  display: flex;
  gap: var(--spacer-lg);
  justify-items: var(--justify);
  flex-direction: var(--direction, row);

  @include for-mobile {
    flex-direction: column;
  }
}

.cms {
  flex-basis: 52%;
}

.image-wrapper {
  align-self: center;
  display: block;
  flex-basis: max(48%, 400px);
  float: var(--float, left);
  border: var(--border-width, 0) solid var(--black);

  @include for-desktop {
    position: sticky;
    top: 0;
    align-self: flex-start;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: $border-radius-2xl;
}

.content-wrapper {
  align-self: center;
}
</style>
