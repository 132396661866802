<template>
  <div :id="blockReference">
    <template v-if="loading">
      <SkeletonLoader height="min(500px, 50vh)" width="100%" />
    </template>
    <template v-else-if="content && content.data && content.data.slices">
      <SliceZone
        :components="components"
        :slices="content.data.slices"
      />
    </template>
  </div>
</template>

<script>
import { onMounted, ref, useContext } from '@nuxtjs/composition-api';
import { components } from '@/slices';
import useCms from '@/prismic/composables/useCmsContent';
import { Logger } from '@/helpers/logger/index.ts';
import { TYPE_STATIC_BLOCK } from '@/prismic/const';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

/**
 * Must work as a slice or direct include
 */
export default {
  name: 'IncludeBlock',
  components: { SkeletonLoader },
  props: {
    slice: {
      type: Object,
      required: false,
      default: () => ({})
    },
    index: {
      type: Number,
      required: false,
      default: 0
    },
    slices: {
      type: Array,
      required: false,
      default: () => ([])
    },
    context: {
      type: null,
      required: false,
      default: null
    },
    blockId: {
      type: String,
      default: '',
      required: false
    }
  },
  setup ({ slice, blockId }) {
    const context = useContext();
    const { search } = useCms(context.$prismic);
    const content = ref({});
    const error = ref('');
    const loading = ref(true);
    const blockIdToLoad = blockId || slice?.primary?.includeBlock?.uid;

    onMounted(async () => {
      if (blockId || slice?.slice_type === 'include_block') {
        Logger.info('Search Prismic for ', blockIdToLoad);
        const result = await search({ type: TYPE_STATIC_BLOCK, key: blockIdToLoad, field: 'uid' });

        content.value = result.content?.value;
        loading.value = result.loading?.value;
        error.value = result.error?.value;
      }
    });

    if (error.value) {
      Logger.error('Failed to load content', error.value);
    }

    return {
      content,
      loading,
      error
    };
  },
  data () {
    return {
      components
    };
  },
  computed: {
    blockReference() {
      return this.blockIdToLoad || this.slice?.primary?.reference;
    }
  }
};
</script>
