<template>
  <NuxtLink :to="cardUrl" class="card">
    <div class="img-wrapper">
      <img
        v-if="data.image && data.image.url"
        :src="data.image.url"
        :alt="data.image.alt"
        height="320"
        width="170"
        loading="lazy"
      >
    </div>
    <h3 class="title">
      {{ data.title }}
    </h3>
    <div class="summary">
      {{ $prismic.asText(data.summary) | truncate(140) }}
    </div>

    <span class="cta">Read more</span>
  </NuxtLink>
</template>

<script>
import { urlFromPrismic } from '@/helpers/getUrl';

export default {
  name: 'CmsListCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    cardUrl() {
      return urlFromPrismic(this.item);
    },
    data() {
      return this.item.data;
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 650px;
  background-color: $color-light-gray;
  border-radius: 16px;
  border: 2px solid $dark;
  padding: clamp(0.7em, 3vw, 2em);
}

.card:hover {
  text-decoration: none;
  box-shadow: 0 9px 8px -7px rgba($color-dark-gray, 0.5);
}

.img-wrapper {
  display: grid;
  place-items: center;
  height: 170px;
  justify-self: start;
}

img {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
}

.title {
  line-height: 1.2;
  font-size: $font-size-base;
  padding-block-end: 0;
  color: $color-primary;
}

.card:hover .title {
  text-decoration: underline;
}

.summary {
  color: $color-dark-gray;
  line-height: 1.3;
  font-size: $font-size-down;
}

.cta {
  color: $navy;
  text-decoration: underline;
  margin-block-start: auto;
}
</style>
