<template>
  <section :id="slice.primary.reference" class="clamped--text">
    <blockquote :style="styleObj">
      <PrismicRichText :field="slice.primary.content" />
    </blockquote>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';

export default {
  name: 'BlockQuote',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    styleObj() {
      return {
        'border-color': `var(--color-${this.slice.primary.backgroundColor?.toLowerCase()})`,
        'border-radius': this.slice.primary.roundedBorder ? '2.5rem' : '0'
      };
    }
  }
};
</script>

<style scoped lang="scss">
section {
  margin-block: var(--spacer-xl);
  max-width: calc($large + 4rem);
}

blockquote {
  color: $navy;
  padding: clamp(1.6em, 5vmin, 6em);
  margin-inline: min(var(--spacer-xl), 2vmin);
  outline-width: 0;
  border-width: 10px;
  border-style: solid;

  @include for-desktop() {
    margin-inline: 0;
    border: 10px solid #fff;
    outline: 10px solid $color-logo;
  }

  ::v-deep p:first-child {
    margin-block-start: 0;
  }

  ::v-deep p:last-child {
    margin-block-end: 0;
  }
}
</style>
