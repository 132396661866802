<template>
  <div class="list-grid clamped">
    <CmsListCard
      v-for="item in list"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script>
import CmsListCard from '@/components/List/CmsListCard';
export default {
  name: 'CmsListContainer',
  components: {
    CmsListCard
  },
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style scoped>
.list-grid {
  display: grid;
  grid-gap: clamp(1em, calc(0.5em + 3.5vmin), 3em);
  grid-template-columns: repeat(auto-fit, minmax(min(45%, var(--max-width, 370px)), 1fr));
}
</style>
